/* Header */

@custom-media --h-mobile-viewport (max-height: 414px) and (max-width: 1024px);
@custom-media --h-desktop-viewport (min-height: 720px) and (min-width: 1280px);
@custom-media --xs-viewport (min-width: 376px);
@custom-media --sm-viewport (min-width: 480px);
@custom-media --md-viewport (min-width: 860px);
@custom-media --lg-viewport (min-width: 1024px);
@custom-media --xlg-viewport (min-width: 1280px);
@custom-media --2xlg-viewport (min-width: 1920px);

.RS-header {
  position: relative;
  @media (--lg-viewport) {
    /* max-width: var(--RS-col-4); */
  }
}

.RS-header--centered {
  text-align: center;
}

.RS-header--wide {

  @media (--md-viewport) {
    max-width: var(--RS-col-7);
  }
}

.RS-header--spaced{
  margin: var(--RS-space-32) auto var(--RS-space-8);

  @media (--md-viewport) {
    margin: 0 auto var(--RS-space-32);
  }
}

.RS-header--spaced-alt{
  margin: var(--RS-space-64) auto var(--RS-space-8);

  @media (--md-viewport) {
    margin: var(--RS-space-64) auto 0;
  }
}

.RS-header--social {
  @media (--md-viewport) {
    max-width: var(--RS-col-6);
  }
}

.RS-header--about-user .RS-header--about-user__decor-wrapper{
  z-index: -1;
  position: absolute;
}

.RS-header--about-user .RS-header--about-user__decor{
  content: '';
  display: none;
  width: calc(var(--RS-space-256) + var(--RS-space-4) + var(--RS-space-32));
  height: calc(var(--RS-space-256) - 0px);
  border-radius: var(--RS-space-64);
  background: var(--RS-accent-color-primary);
  transform: skewX(-8deg) rotateZ(-15deg);

  @media (--md-viewport) {
    display: block;
  }
}

.RS-header__headline {
  margin: 0 0 var(--RS-space-12);
  font-size: var(--RS-font-size-h3);
  text-transform: uppercase;
  letter-spacing: 0.15em;
  display: inline-flex;

  @media (--lg-viewport) {
    font-size: var(--RS-font-size-h3);
  }
}

.RS-header__description {
  font-size: var(--RS-font-size-h1);
  line-height: var(--RS-line-height-md);
  font-weight: var(--RS-font-weight-variable-regular);
  font-family: "PlayfairDisplay-Variable", system-ui, serif;

  @media (--xlg-viewport) {
    font-size: var(--RS-font-size-h1-desktop);
    line-height: var(--RS-line-height-md);
  }
}

.RS-header__description--hero{
  font-size: var(--RS-font-size-h1--hero);
  text-align: center;
  /* display: flex;
  flex-direction: column;
  justify-content: center; */
  /* gap: var(--RS-space-8); */

  @media (--lg-viewport) {
    /* justify-content: flex-start; */
    text-align: left;
  }

  @media (--xlg-viewport) {
    font-size: var(--RS-font-size-h1--hero-desktop);
  }
}

.RS-header__description--pointed{
 display: flex;
 gap: var(--RS-space-6);
}

.RS-header--about-user .RS-header__description{
  max-width: calc(100% - var(--RS-space-16));
}

.RS-header__description + .RS-header__description{
  margin: var(--RS-space-32) 0 0 0;
}

.RS-header__description--hero + .RS-header__description--primary{
  margin: var(--RS-space-8) 0 0 0;
}

.RS-header__description + .RS-header__description--secondary{
  margin: calc(var(--RS-space-48) - var(--RS-space-16)) 0 0 0;

  @media (--md-viewport) {
    margin: calc(var(--RS-space-48) - var(--RS-space-2)) 0 0 0;
  }
}

.RS-section-about-product .RS-header__description + .RS-header__description--primary,
.RS-section-about-product .RS-header__description + .RS-header__description--secondary{
  margin: 0;

  @media (--md-viewport) {
    margin: 0;
  }
}

.RS-header__description:not(.RS-header__description--secondary) + .RS-header__description--secondary{
  margin: calc(var(--RS-space-48) - var(--RS-space-16)) 0 0 0;
}

.RS-header__description.RS-header__description--tile + .RS-header__description--secondary{
  margin: var(--RS-space-24) 0 0 0;
}

.RS-header__description + .RS-button{
  margin: var(--RS-space-48) 0 0 0;
}

.RS-header__description--secondary {
  font-size: var(--RS-font-size-h2);
  line-height: var(--RS-line-height-xlg);
  font-weight: var(--RS-font-weight-variable-light);
  font-family: "PublicSans-Variable", system-ui, sans-serif;


  @media (--lg-viewport) {
    font-size: var(--RS-font-size-h2-desktop);
  }
}

.RS-header__description--tile{
  font-size: var(--RS-font-size-h3);
  position: relative;
  display: flex;
  align-items: center;
  gap: var(--RS-space-8);

  @media (--lg-viewport) {
    font-size: var(--RS-font-size-h3-desktop);
  }
}

.RS-header__headline + .RS-header__description--secondary{
  margin: var(--RS-space-24) 0 0 0;
}

.RS-header__description-link {
  color: inherit;
  position: relative;
  transition: opacity var(--RS-anim-duration-sm) var(--RS-default-transition);
  display: inline-flex;
}

.RS-header__description-link:hover {
  box-shadow: inset 0 0 0 0 transparent;
  opacity: 1;
}

.RS-header__description-link:before{
  content: '';
  display: block;
  width: 100%;
  position: absolute;
  height: var(--RS-space-2);
  bottom: 0;
  left: 0;
  background-color: var(--RS-black-color);
  transform-origin: left;
  transition: transform var(--RS-anim-duration-xlg) var(--RS-default-transition);
}

.RS-header__description-link:hover:before{
  transform-origin: right;
  transform: scaleX(0);
  background-color: var(--RS-brand-color);
}

.RS-header .RS-header__button {
  margin: var(--RS-space-32) 0 0;

  @media (--md-viewport) {
    margin: var(--RS-space-48) 0 0;
  }
}

.RS-header__description {
  transition-duration: calc(var(--RS-anim-duration-xlg) + var(--RS-anim-duration-lg));
}

.RS-header .RS-header__link,
.RS-header__artwork {
  transition-duration: 1.5s;
}

.RS-header-secondary-icon{
  transform: translateY(var(--RS-space-6));
  display: inline-flex;
}

.RS-header-secondary-icon > svg{
  height: calc(var(--RS-space-24) + var(--RS-space-2));
  width: calc(var(--RS-space-24) + var(--RS-space-2));

  @media (--md-viewport) {
    height: calc(var(--RS-space-32) + var(--RS-space-2));
    width: calc(var(--RS-space-32) + var(--RS-space-2));
  }
}

.RS-header__text-italic{
  font-family: 'PlayfairDisplay-Italic-Variable', system-ui, serif;
  /* letter-spacing: -0.025em; */
  /* margin-left: var(--RS-space-16-n); */
}

.RS-header__text-italic--flex{
  margin-left: var(--RS-space-1-n); 

  @media (--md-viewport) {
    margin-left: var(--RS-space-2-n);
  }
}

.RS-header__description--highlighted{
  margin-bottom: var(--RS-space-32);
  background-color: var(--RS-graphite-color);
  padding: var(--RS-space-16) var(--RS-space-24);
  border-radius: var(--RS-space-16);
  color: var(--RS-white-color);

  @media (--md-viewport) {
    margin-bottom: var(--RS-space-64);
  }
}

.RS-header__slider{
  /* display: block; */
  margin: 0 auto;
}

.RS-section-about-process .RS-header__description--tile:before{
  /* content: ''; */
  width: calc(var(--RS-space-32) + var(--RS-space-8));
  height: calc(var(--RS-space-32) + var(--RS-space-8));
  /* width: var(--RS-space-32); */
  /* height: var(--RS-space-32); */
  position: absolute;
  left: 0;
  top:0;
  transform: translateX(-33%) translateY(-20%) rotateZ(45deg);
  border: 3px solid rgba(0,0,0,0.5);
  mask-image: linear-gradient(90deg, rgba(0, 0, 0, 1) 0% 49.9%, transparent 50.1% 100%);
  border-radius: 50%;
  z-index: -1;
  opacity: .2;
}

.RS-list--tiles-about-product .RS-header__description + .RS-button{
  margin: var(--RS-space-24) 0 0 0;
}

.RS-list--tiles-about-product:not(.RS-list--tiles-about-product--alt) .RS-header__description:last-of-type{
  margin-top: 0;
}

.RS-list__tile .RS-header__description--secondary + .RS-header__description--secondary{
  margin: var(--RS-space-8) 0 0 0;
 
  @media (min-width: 860px) {
    margin: var(--RS-space-16) 0 0 0;
   }
 }

 .RS-list__tile .RS-header__description--pointed + .RS-header__description--pointed{
  margin: var(--RS-space-4) 0 0 0;
 
  @media (min-width: 860px) {
    margin: var(--RS-space-4) 0 0 0;
   }
  }

/* .RS-list__tile .RS-header__description--tile + .RS-header__description--secondary{
  margin: var(--RS-space-8) 0 0 0;
  
  @media (min-width: 860px) {
    margin: var(--RS-space-8) 0 0 0;
    }
  } */