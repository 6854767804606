/* Typography */

@custom-media --h-mobile-viewport (max-height: 414px) and (max-width: 1024px);
@custom-media --h-desktop-viewport (min-height: 720px) and (min-width: 1280px);
@custom-media --xs-viewport (min-width: 376px);
@custom-media --sm-viewport (min-width: 480px);
@custom-media --md-viewport (min-width: 860px);
@custom-media --lg-viewport (min-width: 1024px);
@custom-media --xlg-viewport (min-width: 1280px);
@custom-media --2xlg-viewport (min-width: 1920px);

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: var(--RS-font-weight-variable-regular);
}

.RS-simple-link {
  text-decoration: underline;
  transition: color 350ms;
}

.RS-simple-link:hover {
  color: var(--RS-primary-button-color);
}

.RS-link {
  color: inherit;
  text-decoration: none;
  padding-bottom: var(--RS-space-2);
  position: relative;
}

.RS-link:before {
  content: "";
  width: 100%;
  height: var(--RS-space-2);
  bottom: var(--RS-space-2-n);
  left: 0;
  border-radius: var(--RS-space-8);
  transform-origin: 100%;
  transition: transform var(--RS-anim-duration-lg) var(--RS-default-transition);
  background: var(--RS-black-color);
  position: absolute;
  transform: scaleX(0);
}

.RS-link:hover:before {
  transform-origin: 0;
  transform: scaleX(1);
}

.RS-link--active:before,
.RS-link--active:hover:before{
  @media (--md-viewport) {
    transform-origin: 0;
    transform: scaleX(.5);
  }
}

.RS-no-wrap{
  white-space: nowrap;
}

.RS-br-sm{
  display: initial;

  @media (--md-viewport) {
    display: none;
  }
}


.RS-br-md{

  @media (--sm-viewport) {
    display: none;
  }

  @media (--lg-viewport) {
    display: initial;
  }
}

.RS-br-lg{
  display: none;

  @media (--lg-viewport) {
    display: initial;
  }
}

.RS-inline-icon{
  font-size: 80%;
  line-height: 80%;
  display: inline-block;
  min-width: calc(var(--RS-space-12) + var(--RS-space-8));
  margin: 0 var(--RS-space-2);
  margin-bottom: var(--RS-space-2-n);
}

.RS-inline-icon svg path{
  fill: var(--RS-brand-color);
}

.RS-inline-icon--grey svg path{
  fill: var(--RS-dark-grey-color-wcag);
}

.RS-inline-icon--graphite svg path{
  fill: var(--RS-graphite-color);
}


.RS-inline-icon--black svg path{
  fill: var(--RS-black-color);
}

.RS-inline-icon--multiline{
  margin-top: var(--RS-space-6);
  margin-right: var(--RS-space-6);
  min-width: calc(var(--RS-space-12) - var(--RS-space-2));

  @media (--lg-viewport) {
    margin-top: var(--RS-space-4);
    margin-right: var(--RS-space-12);
    min-width: calc(var(--RS-space-12) + var(--RS-space-2));
  }
}

.RS-inline-icon--tile-header{
  min-width: calc(var(--RS-space-12) + var(--RS-space-2));
  transform: translateY(var(--RS-space-2-n));

  @media (--lg-viewport) {
    min-width: calc(var(--RS-space-12) + var(--RS-space-8));
    margin-right: var(--RS-space-4);
    transform: translateY(var(--RS-space-2-n));
  }
}

.RS-inline-icon--tile-header-secondary{
  min-width: calc(var(--RS-space-12) + var(--RS-space-2));
  margin-right: var(--RS-space-4);
  transform: translateY(var(--RS-space-2-n));

  @media (--lg-viewport) {
    min-width: calc(var(--RS-space-12) + var(--RS-space-6));
    margin-right: var(--RS-space-8);
    transform: translateY(var(--RS-space-2-n));
  }
}

.RS-inline-icon--hero-marquee{
  min-width: calc(var(--RS-space-12) + var(--RS-space-2));
  transform: translateY(var(--RS-space-2));
  position: absolute;

  @media (--lg-viewport) {
    min-width: calc(var(--RS-space-12) + var(--RS-space-6));
    margin-right: var(--RS-space-4);
    transform: translateX(var(--RS-space-4)) translateY(var(--RS-space-2));
  }
}

.RS-font-weight-bold{
  font-weight: var(--RS-font-weight-variable-bold);
}

.RS-font-weight-regular{
  font-weight: var(--RS-font-weight-variable-regular);
}

/* .RS-font-style-italic{
  display: inline-flex;
  transform: skewX(-8deg);
} */

.RS-disclaimer{
  font-size: var(--RS-font-size-disclaimer);
  font-weight: var(--RS-font-weight-variable-light);
  color: var(--RS-dark-grey-color);

  @media (--lg-viewport) {
    font-size: var(--RS-font-size-disclaimer-desktop);
  }
}

.RS-font-color-brand{
  color: var(--RS-brand-color);
}

.RS-academic-title{
  /* font-size: var(--RS-font-size-p); */
  /* font-weight: var(--RS-font-weight-variable-bold); */
  font-family: 'PlayfairDisplay-Italic-Variable', serif;

  @media (--lg-viewport) {
    /* font-size: var(--RS-font-size-p-desktop); */
  }
}

.RS-font-crossed-out{
  position: relative;
}

.RS-font-crossed-out:after{
  content: '';
  width: 110%;
  height: 5%;
  background-color: var(--RS-black-color);
  display: inline-block;
  position: absolute;
  top: 60%;
  left: -5%;
  border-radius: var(--RS-space-16);
  /* transform: scaleY(10%); */
}

/* .RS-font-transparent{
  opacity: .5;
} */

.RS-font-family-main{
  font-family: 'PublicSans-Variable', sans-serif;
}
