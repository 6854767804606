
@custom-media --h-mobile-viewport (max-height: 414px) and (max-width: 1024px);
@custom-media --h-desktop-viewport (min-height: 720px) and (min-width: 1280px);
@custom-media --xs-viewport (min-width: 376px);
@custom-media --sm-viewport (min-width: 480px);
@custom-media --md-viewport (min-width: 860px);
@custom-media --lg-viewport (min-width: 1024px);
@custom-media --xlg-viewport (min-width: 1280px);
@custom-media --2xlg-viewport (min-width: 1920px);

.RS-list {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  font-weight: var(--RS-font-weight-variable-bold);
  position: relative;
  padding: var(--RS-space-32) 0 0 0;

  @media (--md-viewport) {
    /* width: auto;
    min-width: calc(var(--RS-space-128) + var(--RS-space-32)); */
  }
}

/* .RS-list li{
  padding: 0 0 var(--RS-space-16) 0;

  font-size: var(--RS-font-size-p);
  line-height: var(--RS-line-height-md);

  @media (--lg-viewport) {
    font-size: var(--RS-font-size-p-desktop);
  }

} */

.RS-list--tiles{
  flex-direction: column;
  align-items: center;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  gap: var(--RS-space-32);
  padding: var(--RS-space-32) 0 0 0;

  @media (--md-viewport) {
    flex-direction: row;
    gap: var(--RS-space-64);
    padding: var(--RS-space-32) 0 0 0;
  }
}

/* .RS-list--tiles-about-facts{
  align-items: stretch;
} */


.RS-list--tiles-about-user{
  padding: var(--RS-space-32) 0 0 0;
  @media (--md-viewport) {
    flex-direction: column;
    padding: 0;
  }
}

.RS-list--tiles-about-process{
  padding: var(--RS-space-16) 0 0 0;
  gap: var(--RS-space-32);
  @media (--md-viewport) {
    flex-direction: column;
    padding: 0;
    gap: 0;
  }
}

.RS-list--tiles-about-benefits{

  flex-direction: column;
  /* gap: 0; */
  margin: 0 0 0 0;
  align-items: stretch;
  
  @media (--md-viewport) {
    flex-direction: row;
    /* gap: 0; */
    margin: var(--RS-space-32) 0 0 0;
  }
}

.RS-list--tiles-about-product{

  flex-direction: column;
  gap: var(--RS-space-32);
  margin: 0 0 0 0;
  /* align-items: stretch; */
  padding: 0;
  
  @media (--md-viewport) {
    flex-direction: row;
    /* gap: 0; */
    margin: 0 0 0 0;
    padding: 0;
  }
}

.RS-list--tiles-about-product-alt{

  flex-direction: column;
  gap: var(--RS-space-32);
  margin: var(--RS-space-16) 0 0 0;
  align-items: stretch;
  padding: var(--RS-space-48) 0 0 0;
  
  @media (--md-viewport) {
    flex-direction: row;
    /* gap: 0; */
    margin: var(--RS-space-32) 0 0 0;
    padding: var(--RS-space-32) 0 0 0;
  }
}

.RS-list--tiles-about-products{

  flex-direction: column;
  gap: var(--RS-space-32);
  margin: var(--RS-space-32) 0 0 0;
  /* align-items: stretch; */
  padding: 0;
  
  @media (--md-viewport) {
    flex-direction: row;
    /* gap: 0; */
    margin: var(--RS-space-64) 0 0 0;
    padding: 0;
  }
}


.RS-list--tiles-about-report-content{

  flex-direction: column;
  gap: var(--RS-space-32);
  margin: var(--RS-space-16) 0 0 0;
  align-items: stretch;
  padding: var(--RS-space-24) 0 0 0;
  
  @media (--md-viewport) {
    /* flex-direction: row; */
    /* gap: 0; */
    margin: var(--RS-space-32) 0 0 0;
    padding: var(--RS-space-32) 0 0 0;
  }
}

.RS-list--tiles-inner{
  flex-direction: column;
  padding: 0;
  gap: var(--RS-space-16);
  margin: 0;

  @media (--md-viewport) {
    padding: 0;
    gap: var(--RS-space-16);
  }
}

.RS-list__tile{
  padding: var(--RS-space-8) var(--RS-space-12) var(--RS-space-12) var(--RS-space-12);
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--RS-space-16);
  
  border-radius: var(--RS-space-24);
  /* max-width: var(--RS-col-3); */

  font-size: var(--RS-font-size-p);
  line-height: var(--RS-line-height-md);

  @media (--lg-viewport) {
    font-size: var(--RS-font-size-p-desktop);
    padding: var(--RS-space-16) var(--RS-space-16) var(--RS-space-16) var(--RS-space-16);
  }
}

.RS-list__tile--about-user{
  border: 1px solid var(--RS-light-grey-color);
  border-radius: var(--RS-space-24);
  background-color: var(--RS-white-color);
  gap: 0;
  padding: var(--RS-space-24);
  position: relative;
  overflow: hidden;

  @media (--lg-viewport) {
    padding: var(--RS-space-32) var(--RS-space-32);
  }
}

.RS-list__tile--about-user:before{
  content: "";
  width: calc(var(--RS-space-128) + var(--RS-space-64));
  height: calc(var(--RS-space-128) + var(--RS-space-64));
  top: calc(0px - (var(--RS-space-128) + var(--RS-space-64))/2);
  right: calc(0px - (var(--RS-space-128) + var(--RS-space-64))/2);
  border-radius: var(--RS-space-48);
  z-index: -1;
  display: block;
  position: absolute;
  /* border: 2px solid var(--RS-light-grey-color); */

  @media (--lg-viewport) {
    width: calc(var(--RS-space-128) + var(--RS-space-64));
    height: calc(var(--RS-space-128) + var(--RS-space-64));
    top: calc(0px - (var(--RS-space-128) + var(--RS-space-64))/2);
    right: calc(0px - (var(--RS-space-128) + var(--RS-space-64))/2);
  }
}

.RS-list__tile--about-user-first:before{
  background: var(--RS-accent-color-primary);
  transform: skewX(8deg) rotateZ(15deg);
}

.RS-list__tile--about-user-second:before{
  background: var(--RS-accent-color-secondary);
  transform: skewX(8deg) rotateZ(-15deg);
}

.RS-list__tile--about-user-third:before{
  background: var(--RS-accent-color-primary);
  transform: skewX(-4deg) rotateZ(15deg);
}

.RS-list__tile--about-user-fourth:before{
  background: var(--RS-accent-color-secondary);
  transform: skewX(4deg) rotateZ(-15deg);
}

.RS-list__tile--about-user-fifth:before{
  background: var(--RS-accent-color-primary);
  transform: skewX(-6deg) rotateZ(5deg);
}

.RS-list__tile--about-user-sixth:before{
  background: var(--RS-accent-color-secondary);
  transform: skewX(6deg) rotateZ(-5deg);
}


.RS-list__tile--about-process{
  gap: var(--RS-space-24);

  padding: 0;
  flex-direction: column;
  margin: 0;
  position: relative;

  @media (--lg-viewport) {
    gap: var(--RS-space-48);
    margin: 0;
    flex-direction: row;
    padding: var(--RS-space-24) 0;
  }
}

.RS-list__tile--about-benefits{
  /* border: 1px solid var(--RS-light-grey-color); */
  border-radius: var(--RS-space-24);
  /* background-color: var(--RS-white-color); */
  background-color: var(--RS-accent-color-secondary); 
  gap: 0;
  padding: var(--RS-space-24);

  @media (--lg-viewport) {
    padding: var(--RS-space-32) var(--RS-space-32);
  }
}

.RS-list__tile--about-product{

  align-items: flex-start;
  padding: 0;

  @media (--lg-viewport) {
    gap: var(--RS-space-32);
  }
}

.RS-list__tile--hero-about-product:first-of-type{
  align-items: center;
}

.RS-list__tile--hero-about-product{

  /* padding: 0; */
  align-items: center;

  @media (--lg-viewport) {
    gap: var(--RS-space-32);
  }
}


.RS-list__tile--about-product-popup{
  justify-content: center;
}

.RS-list__tile--about-product-alt{
  border: 1px solid var(--RS-light-grey-color);
  border-radius: var(--RS-space-24);
  background-color: var(--RS-white-color);
  gap: 0;
  padding: var(--RS-space-24);

  @media (--lg-viewport) {
    padding: var(--RS-space-32) var(--RS-space-32);
  }
}

.RS-list__tile--about-products{

  gap: var(--RS-space-24);
  align-items: flex-start;
  justify-content: space-between;
  padding: 0;

  @media (--lg-viewport) {
    gap: var(--RS-space-32);
  }
}

.RS-list__tile--about-products:first-of-type .RS-header__description--primary{
  margin-top: auto;
}


.RS-list__tile--about-product-alt:nth-of-type(2),
.RS-list__tile--about-product-alt:nth-of-type(4){
  border: 1px solid var(--RS-accent-color-primary);
  background-color: var(--RS-accent-color-primary);
}

.RS-list__tile--about-product-alt .RS-header__description--tile{
  align-items: flex-start;
}

.RS-list__tile--about-product-alt .RS-header__description--tile svg{
  margin-bottom: unset;
  transform: translateY(67%);
}

.RS-list__tile--about-report-content{
  border: 1px solid var(--RS-light-grey-color);
  border-radius: var(--RS-space-24);
  background-color: var(--RS-white-color);
  gap: var(--RS-space-24);
  padding: var(--RS-space-24);
  flex-direction: column;


  @media (--md-viewport) {
    flex-direction: column;
    gap: var(--RS-space-48);
  }

  @media (--lg-viewport) {
    padding: var(--RS-space-32) var(--RS-space-32);
  }
}

.RS-list--tiles-about-report-content:nth-of-type(even) .RS-list__tile--about-report-content{
  flex-direction: column;
  /* background-color: var(--RS-accent-color-primary);
  border: unset; */
  
  @media (--md-viewport) {
    flex-direction: column;
  }
}

.RS-list__tile--about-report-content > div{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  @media (--md-viewport) {
    width: 100%;
  }
}

.RS-list__tile--about-report-content img{
  border: var(--RS-space-1) solid var(--RS-light-grey-color);
  box-sizing: content-box;
  width: 100%;
}


/* .RS-list__tile--about-report-content:nth-of-type(2),
.RS-list__tile--about-report-content:nth-of-type(4){
  border: 1px solid var(--RS-accent-color-primary);
  background-color: var(--RS-accent-color-primary);
} */

.RS-list__tile--about-facts{
  border-radius: var(--RS-space-24);
  gap: 0;
  padding: var(--RS-space-24);
  gap: var(--RS-space-16);
  height: 100%;
  overflow: hidden;

  @media (--lg-viewport) {
    padding: var(--RS-space-32) var(--RS-space-32);
  }
}

.RS-list__tile--about-facts--first{
  background-color: var(--RS-accent-color-secondary);
}


.RS-list__tile--about-facts--third{
  background-color: var(--RS-accent-color-primary);
}

.RS-list__tile--about-facts--second{
  border: 1px solid var(--RS-light-grey-color);
}

.RS-list__tile--about-facts > p:first-of-type{
  padding: var(--RS-space-8) var(--RS-space-16);
  border-radius: var(--RS-space-6);
  align-self: flex-start;
}

.RS-list__tile--about-facts--first > p:first-of-type{
  background: var(--RS-white-color);
}

.RS-list__tile--about-facts--second > p:first-of-type{
  background: var(--RS-accent-color-primary);
}

.RS-list__tile--about-facts--third > p:first-of-type{
  background: var(--RS-accent-color-secondary);
  background: var(--RS-white-color);
}

.RS-list__tile--about-facts span svg{
  position: absolute;
  height: calc(var(--RS-space-64) - var(--RS-space-8));
}

.RS-list__tile--about-facts--first svg{
  bottom: var(--RS-space-6-n);
  right: var(--RS-space-12);
  transform: rotateZ(0deg);
}

.RS-list__tile--about-facts--second svg{
  bottom: calc(10% - var(--RS-space-6-n));
  right: var(--RS-space-6);
  transform: rotateZ(-90deg);
  @media (--lg-viewport) {
    bottom: calc(75% - var(--RS-space-6-n));
  }
}

.RS-list__tile--about-facts--third svg{
  fill: var(--RS-white-color);
  top: var(--RS-space-6-n);
  right: var(--RS-space-12);
  transform: rotateZ(180deg);
}

.RS-list__tile--about-facts--first svg path{
  fill: var(--RS-white-color);
}

.RS-list__tile--about-facts--second svg path{
  fill: var(--RS-light-grey-color);
  opacity: .5;
}


.RS-list__tile--about-facts--third svg path{
  fill: var(--RS-white-color);
}

.RS-list__tile--about-process > div{
  padding: var(--RS-space-24);
  position: relative;
}

.RS-list__tile--about-process > div:first-of-type{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--RS-space-32);
  min-width: var(--RS-space-96);
  color: var(--RS-brand-color);
}

.RS-list__tile--about-process > div:first-of-type:before{
  content: '';
  position: absolute;
  left: 50%;
  /* width: var(--RS-space-4); */
  height: calc(100% + var(--RS-space-48));
  border: 1px solid var(--RS-light-grey-color);
  z-index: -2;

  /* background-image: linear-gradient(black 33%, rgba(255,255,255,0) 0%);
  background-position: right;
  background-size: 10px 60px;
  background-repeat: repeat-y; */

  /* background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg id='b' data-name='Layer_2' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 241'%3E%3Cg id='c' data-name='b'%3E%3Cpath d='M.5,241c-.28,0-.5-.22-.5-.5v-16c0-.28.22-.5.5-.5s.5.22.5.5v16c0,.28-.22.5-.5.5ZM.5,209c-.28,0-.5-.22-.5-.5v-16c0-.28.22-.5.5-.5s.5.22.5.5v16c0,.28-.22.5-.5.5ZM.5,177c-.28,0-.5-.22-.5-.5v-16c0-.28.22-.5.5-.5s.5.22.5.5v16c0,.28-.22.5-.5.5ZM.5,145c-.28,0-.5-.22-.5-.5v-16c0-.28.22-.5.5-.5s.5.22.5.5v16c0,.28-.22.5-.5.5ZM.5,113c-.28,0-.5-.22-.5-.5v-16c0-.28.22-.5.5-.5s.5.22.5.5v16c0,.28-.22.5-.5.5ZM.5,81c-.28,0-.5-.22-.5-.5v-16c0-.28.22-.5.5-.5s.5.22.5.5v16c0,.28-.22.5-.5.5ZM.5,49c-.28,0-.5-.22-.5-.5v-16c0-.28.22-.5.5-.5s.5.22.5.5v16c0,.28-.22.5-.5.5ZM.5,17c-.28,0-.5-.22-.5-.5V.5C0,.22.22,0,.5,0s.5.22.5.5v16c0,.28-.22.5-.5.5Z' style='fill: %230655a5;'/%3E%3C/g%3E%3C/svg%3E"); */


}

.RS-list__tile--about-process:first-of-type > div:first-of-type:before{
  mask-image: linear-gradient(180deg, transparent 0%, rgba(0, 0, 0, 1) 20%  100%);
}

.RS-list__tile--about-process:nth-of-type(2) > div:first-of-type:before{
}

.RS-list__tile--about-process:last-of-type > div:first-of-type:before{
  mask-image: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%  80%, transparent 100%);
}

.RS-list__tile--about-process > div:first-of-type:after{
  content: '';
  display: block;
  position: absolute;
  width: var(--RS-space-96);
  height: var(--RS-space-96);
  border-radius: var(--RS-space-32);
  background: var(--RS-accent-color-primary);
  z-index: -1;
  transform: skewX(8deg) rotateZ(15deg);
}

.RS-list__tile--about-process:nth-of-type(3) > div:first-of-type:after{
  background: var(--RS-accent-color-secondary);
}

.RS-list__tile--about-process:nth-of-type(2) > div:first-of-type:after{
  background: var(--RS-graphite-color);
  transform: skewX(-8deg) rotateZ(-15deg);
}

.RS-list__tile--about-process > div:first-of-type{
  color: var(--RS-black-color);
}

.RS-list__tile--about-process:nth-of-type(2) > div:first-of-type{
  color: var(--RS-white-color);
}

.RS-list__tile--about-process > div:last-of-type{
  /* border: 1px solid var(--RS-accent-color-secondary);
  border-radius: var(--RS-space-24);
  background-color: var(--RS-white-color); */
}


.RS-list__tile--about-process > div:first-of-type > picture{
  position: absolute;
  opacity: 1;
}

.RS-list__tile--about-process > div:first-of-type > p > span{
  transform: translateY(-10%);
  display: flex;
}

.RS-list__tile--about-process > div:first-of-type > svg{
  height: calc(var(--RS-space-128) + var(--RS-space-64));
  position: absolute;
  z-index: -1;
  display: none;
}

.RS-list__tile--about-process:nth-of-type(2) > div:first-of-type > svg{
  transform: rotateZ(180deg);
}


.RS-list__tile--about-process:nth-of-type(3) > div:first-of-type > svg{
  transform: rotateZ(0deg);
}

.RS-list__tile--about-process .RS-header__description--tile{
  justify-content: center;

  @media (--md-viewport) {
    justify-content: flex-start;
  }
}



.RS-list__tile > svg{
  fill: var(--RS-accent-color-primary);
  position: absolute;
  inset: 0;
  inline-size: 100%;
  block-size: 100%;
  width: calc(100% + var(--RS-space-12)*2);
  height: calc(100% + var(--RS-space-8)*2);
  top: calc(var(--RS-space-8)*-1);
  left: calc(var(--RS-space-12)*-1);
  overflow: visible;
  transition: transform var(--RS-anim-duration-md) var(--RS-bounce-transition);
  filter: url('#RS-highlight-svg-filter');
  z-index: -1;
}



.RS-list__tile:nth-of-type(even) > svg{
  fill: var(--RS-accent-color-secondary);
}

.RS-list--tiles-about-benefits:nth-of-type(2) .RS-list__tile:nth-of-type(even) > svg{
  @media (--md-viewport) {
    fill: var(--RS-accent-color-primary);
  }
}

.RS-list--tiles-about-benefits:nth-of-type(2) .RS-list__tile:nth-of-type(odd) > svg{
  @media (--md-viewport) {
    fill: var(--RS-accent-color-secondary);
  }
}

.RS-list__tile-header{
  display: flex;
  gap: var(--RS-space-16);
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (--md-viewport) {
    justify-content: flex-start;
    flex-direction: row;
  }
}

.RS-list__tile-header + p{
  text-align: center;
  @media (--md-viewport) {
    text-align: left;
  }
}

.RS-list__tile-icon-wrapper{
  position: relative;
}

.RS-list__tile picture{
  /* opacity: .89; */
}

/* .RS-list__tile:nth-of-type(2) picture{
  transform: rotateZ(34deg);
}

.RS-list__tile:nth-of-type(3) picture{
  transform: rotateZ(89deg);
} */

/* .RS-list__tile--about-product:first-of-type picture{
  filter: brightness(1.1);
} */

.RS-list__tile-icon-wrapper{
  width: calc(var(--RS-space-64) + var(--RS-space-16));
  @media (--md-viewport) {
    width: auto;
  }
}

.RS-list__tile-icon > svg{
  width: calc(var(--RS-space-48) - var(--RS-space-12));
  height: calc(var(--RS-space-48) - var(--RS-space-12));
  fill: var(--RS-brand-color);
  
  @media (--md-viewport) {
    width: calc(var(--RS-space-48) - var(--RS-space-6));
    height: calc(var(--RS-space-48) - var(--RS-space-6));
  }
}

.RS-list__tile-icon{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.RS-tile-product-bg{
  width: calc(var(--RS-space-256) + var(--RS-space-64));
  height: calc(var(--RS-space-256) + var(--RS-space-64));
  border-radius: var(--RS-space-64);
  position: absolute;
}

.RS-tile-product-bg:nth-of-type(1){
  top: var(--RS-space-32-n);
  right: var(--RS-space-32-n);
  background-color: var(--RS-accent-color-primary);
  z-index: -1;
  transform: skewX(8deg) rotateZ(15deg);

  @media (--md-viewport) {
    top: 0;
    right: 0;
  }
}

.RS-tile-product-bg:nth-of-type(2){
  bottom: var(--RS-space-32-n);
  left: var(--RS-space-32-n);
  background-color: var(--RS-accent-color-secondary);
  transform: skewX(-8deg) rotateZ(-15deg);
  z-index: -1;

  @media (--md-viewport) {
    bottom: 0;
    left: 0;
  }
}

.RS-tile-product-image--desktop{
  /* width: 794px;
  height: 558px; */
  margin-left: calc(var(--RS-space-256-n) + var(--RS-space-192-n));
}


.RS-tile-product-image--mobile{
  position: absolute;
  /* width: 382px;
  height: 486px; */
  right: 0;
  bottom: 0;
}

.RS-tile-product-bg--hero{
  width: calc(var(--RS-space-256) + var(--RS-space-64));
  height: calc(var(--RS-space-256) + var(--RS-space-64));
  border-radius: var(--RS-space-64);
  position: absolute;
}

.RS-tile-product-bg--hero:nth-of-type(1){
  top: var(--RS-space-32-n);
  right: var(--RS-space-32-n);
  background-color: var(--RS-accent-color-primary);

  transform: skewX(8deg) rotateZ(15deg);
  z-index: -1;

  @media (--md-viewport) {
    top: 0;
    right: 0;
  }
}

.RS-tile-product-bg--hero:nth-of-type(2){
  bottom: var(--RS-space-32-n);
  left: var(--RS-space-32-n);
  background-color: var(--RS-accent-color-secondary);
  transform: skewX(-8deg) rotateZ(-15deg);
  z-index: -1;

  @media (--md-viewport) {
    bottom: 0;
    left: 0;
  }
}

.RS-tile-product-image--hero-desktop{
  /* width: 794px;
  height: 558px; */
  margin-right: calc(var(--RS-space-256-n) + var(--RS-space-192-n));
}


.RS-tile-product-image--hero-mobile{
  position: absolute;
  /* width: 382px;
  height: 486px; */
  left: 0;
  bottom: 0;
}


.RS-header__description.RS-tile-process-number{
  transition-duration: var(--RS-anim-duration-xs);
}

.RS-list--tiles-about-benefits:nth-of-type(1){

  @media (--md-viewport) {
    margin-top: var(--RS-space-48);
  }
}


/* 
.RS-list--tiles-about-benefits:nth-of-type(1) .RS-list__tile:nth-of-type(1) > svg,
.RS-list--tiles-about-benefits:nth-of-type(2) .RS-list__tile:nth-of-type(2) > svg{
  fill: var(--RS-graphite-color);
}

.RS-list--tiles-about-benefits:nth-of-type(1) .RS-list__tile:nth-of-type(2) > svg,
.RS-list--tiles-about-benefits:nth-of-type(2) .RS-list__tile:nth-of-type(1) > svg{
  fill: var(--RS-accent-color-secondary);
}

.RS-list--tiles-about-benefits:nth-of-type(1) .RS-list__tile:nth-of-type(1),
.RS-list--tiles-about-benefits:nth-of-type(2) .RS-list__tile:nth-of-type(2){
  color: var(--RS-white-color);
}

.RS-list--tiles-about-benefits:nth-of-type(1) .RS-list__tile:nth-of-type(1) p:first-of-type span,
.RS-list--tiles-about-benefits:nth-of-type(2) .RS-list__tile:nth-of-type(2) p:first-of-type span{
  font-weight: var(--RS-font-weight-variable-regular);
}

.RS-list--tiles-about-benefits:nth-of-type(1) .RS-list__tile:nth-of-type(1) p:last-of-type,
.RS-list--tiles-about-benefits:nth-of-type(2) .RS-list__tile:nth-of-type(2) p:last-of-type{
  font-weight: var(--RS-font-weight-variable-light);
}

.RS-list--tiles-about-benefits:nth-of-type(1) .RS-list__tile:nth-of-type(1) p:last-of-type span,
.RS-list--tiles-about-benefits:nth-of-type(2) .RS-list__tile:nth-of-type(2) p:last-of-type span{
  font-weight: var(--RS-font-weight-variable-regular);
} */


.RS-list--tiles-about-benefits:nth-of-type(1) .RS-list__tile:nth-of-type(1){
  /* color: var(--RS-white-color); */
  /* background-color: var(--RS-graphite-color); */
  /* border: unset; */
  background-color: unset;
  border: 2px solid;
  border-top-color: var(--RS-accent-color-primary);
  border-right-color: var(--RS-accent-color-primary);
  border-bottom-color: var(--RS-accent-color-secondary);
  border-left-color: var(--RS-accent-color-secondary);
}

.RS-list--tiles-about-benefits:nth-of-type(3) .RS-list__tile:nth-of-type(1){
  /* color: var(--RS-white-color); */
  background-color: var(--RS-accent-color-primary);
  border: unset;
}

.RS-list--tiles-about-benefits:nth-of-type(3) .RS-list__tile:nth-of-type(2){
  /* color: var(--RS-white-color);
  background-color: var(--RS-graphite-color);
  border: unset; */
  background-color: unset;
  border: 2px solid;
  border-top-color: var(--RS-accent-color-primary);
  border-right-color: var(--RS-accent-color-primary);
  border-bottom-color: var(--RS-accent-color-secondary);
  border-left-color: var(--RS-accent-color-secondary);

  @media (--md-viewport) {
    border-top-color: var(--RS-accent-color-secondary);
    border-right-color: var(--RS-accent-color-secondary);
    border-bottom-color: var(--RS-accent-color-primary);
    border-left-color: var(--RS-accent-color-primary);
  }
}


.RS-list--tiles-about-benefits:nth-of-type(1) .RS-list__tile:nth-of-type(1) .RS-inline-icon svg{
  width: calc(var(--RS-space-32) + var(--RS-space-4));
}

.RS-list--tiles-about-benefits:nth-of-type(2) .RS-list__tile:nth-of-type(1) .RS-inline-icon svg{
  width: calc(var(--RS-space-32) + var(--RS-space-4));
}


.RS-list--tiles-about-benefits:nth-of-type(3) .RS-list__tile:nth-of-type(2) svg{
  width: calc(var(--RS-space-32) + var(--RS-space-6));
}

.RS-list--tiles-about-benefits:nth-of-type(3) .RS-list__tile:nth-of-type(1) svg{
  width: calc(var(--RS-space-32) + var(--RS-space-4));
}


.RS-list--tiles-about-benefits:nth-of-type(1) .RS-list__tile:nth-of-type(1) svg path{
  fill: var(--RS-brand-color);
}

.RS-list--tiles-about-benefits:nth-of-type(2) .RS-list__tile:nth-of-type(1) .RS-inline-icon svg path{
  fill: var(--RS-black-color);
}

.RS-list--tiles-about-benefits:nth-of-type(3) .RS-list__tile:nth-of-type(1) svg path{
  fill: var(--RS-black-color);
}

.RS-list--tiles-about-benefits:nth-of-type(3) .RS-list__tile:nth-of-type(2) svg path{
  fill: var(--RS-brand-color);
}

.RS-list--tiles-about-product-alt .RS-list__tile:nth-of-type(2) svg path,
.RS-list--tiles-about-product-alt .RS-list__tile:nth-of-type(4) svg path{
  fill: var(--RS-black-color);
}

.RS-list__tile--accent-primary,
.RS-list__tile--accent-secondary,
.RS-list__tile--brand,
.RS-list__tile--graphite,
.RS-list__tile--white,
.RS-list__tile--no-bg,
.RS-list__tile--no-bg-accents{
  padding: var(--RS-space-24);
  @media (--lg-viewport) {
    padding: var(--RS-space-32) var(--RS-space-32);
  }
}

.RS-list__tile--accent-primary,
.RS-list__tile--accent-secondary,
.RS-list__tile--brand,
.RS-list__tile--graphite,
.RS-list__tile--white,
.RS-list__tile--no-bg,
.RS-list__tile--no-bg-accents{
  
}

.RS-list__tile--accent-primary{
  background-color: var(--RS-accent-color-primary);
  
}

.RS-list__tile--accent-secondary{
  background-color: var(--RS-accent-color-secondary);
}

.RS-list__tile--brand{
  background-color: var(--RS-brand-color);
  color: var(--RS-white-color);
}

.RS-list__tile--graphite{
  background-color: var(--RS-graphite-color);
  color: var(--RS-white-color);
}

.RS-list__tile--white{
  background-color: var(--RS-white-color);
}


.RS-list__tile--graphite p{
  color: var(--RS-white-color);
}

.RS-list__tile--no-bg{
  border: var(--RS-space-1) solid var(--RS-light-grey-color);
}

.RS-list__tile--no-bg-accents{
  border: var(--RS-space-2) solid var(--RS-accent-color-primary);
  border-right-color: var(--RS-accent-color-secondary);
  border-bottom-color: var(--RS-accent-color-secondary);
}

.RS-form .RS-header__description + .RS-header__description {
  margin-top: 0;
}

.RS-form .RS-header__description + .RS-button{
  margin-top: var(--RS-space-16);
}

.RS-list__tile .RS-header__description + .RS-button{
  margin-top: var(--RS-space-12);
}

.RS-list__tile img{
  border-radius: calc(var(--RS-space-24) - var(--RS-space-4));
}

.RS-list__tile .RS-pill{
  background-color: var(--RS-graphite-color);
  padding: calc(var(--RS-space-6) + var(--RS-space-1)) var(--RS-space-16);
  color: var(--RS-white-color);
  font-size: var(--RS-font-size-disclaimer);
  width: fit-content;
  border-radius: var(--RS-space-16);
  gap: var(--RS-space-4);
  text-align: center;
  transition: transform var(--RS-anim-duration-lg) var(--RS-default-transition), opacity var(--RS-anim-duration-xs) var(--RS-default-transition);
  transform: translate3d(0, 0, 0);
  display: flex;
  align-items: center;

  @media (--xs-viewport) {
  }

  @media (--md-viewport) {
  }

  @media (--lg-viewport) {
    font-size: var(--RS-font-size-disclaimer-desktop);
    padding: var(--RS-space-8) var(--RS-space-16);
  }
}

.RS-list__tile--about-products .RS-pill{
  margin-top: calc(var(--RS-space-32-n) - var(--RS-space-4));
  position: absolute;

  @media (--lg-viewport) {
    margin-top: var(--RS-space-48-n);
  }
}

.RS-list__tile .RS-pill:before,
.RS-list__tile .RS-pill:after{
  content: '';
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000000' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.7878 3.10215C11.283 2.09877 12.7138 2.09876 13.209 3.10215L15.567 7.87987L20.8395 8.64601C21.9468 8.80691 22.3889 10.1677 21.5877 10.9487L17.7724 14.6676L18.6731 19.9189C18.8622 21.0217 17.7047 21.8627 16.7143 21.342L11.9984 18.8627L7.28252 21.342C6.29213 21.8627 5.13459 21.0217 5.32374 19.9189L6.2244 14.6676L2.40916 10.9487C1.60791 10.1677 2.05005 8.80691 3.15735 8.64601L8.42988 7.87987L10.7878 3.10215Z' fill='%23ffe9ca'/%3E%3C/svg%3E%0A");
  background-size: contain;
  display: inline-block;
  width: calc(var(--RS-space-12) - var(--RS-space-1));
  height: calc(var(--RS-space-12) - var(--RS-space-1));
  margin-right: var(--RS-space-1);

  @media (--lg-viewport) {
    width: calc(var(--RS-space-12) + var(--RS-space-1));
    height: calc(var(--RS-space-12) + var(--RS-space-1));
    margin-right: var(--RS-space-2);
    transform: translateY(-5%);
  }
}
