/* Background */

@custom-media --h-mobile-viewport (max-height: 414px) and (max-width: 1024px);
@custom-media --h-desktop-viewport (min-height: 720px) and (min-width: 1280px);
@custom-media --xs-viewport (min-width: 376px);
@custom-media --sm-viewport (min-width: 480px);
@custom-media --md-viewport (min-width: 860px);
@custom-media --lg-viewport (min-width: 1024px);
@custom-media --xlg-viewport (min-width: 1280px);
@custom-media --2xlg-viewport (min-width: 1920px);

.RS-background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  pointer-events: none;
  z-index: -1;
  
  /* @media (--lg-viewport) {
    align-items: center;
  } */
}


.RS-background > picture > img{
  width: 200%;
  margin-left: -50%;
  opacity: .5;
  
  @media (--md-viewport) {
    width: 150%;
    margin-left: -25%;
  }

  @media (--lg-viewport) {
    width: 100%;
    margin-left: auto;
  }
}


.RS-section-about-process .RS-background{
  top: 33%;
  /* bottom: 5%; */
  left: unset;
  right: 3.5%;
  /* transform-origin: right; */
}


.RS-section-about-facts .RS-background{
  top: -10%;
}


.RS-section-newsletter .RS-background{
  top: unset;
  bottom: 15%;
}

.RS-background__decoration{
  position: absolute;
}

.RS-hero .RS-background .RS-background__decoration:first-of-type{
  right: 0%;
  top: 10%;

  @media (--lg-viewport) {
    right: 16.5%;
    top: 25%;
  }
}

.RS-hero .RS-background .RS-background__decoration:last-of-type{
  left: -0%;
  bottom: 50%;

  @media (--lg-viewport) {
    left: 16.5%;
    bottom: 25%;
  }
}


.RS-section-about-project .RS-background .RS-background__decoration:first-of-type{
  right: -1%;
  top: 5%;
}

.RS-footer .RS-background__decoration:first-of-type{
  bottom: 50%;
  left: 15%;
}

.RS-background__decoration picture{
  width: 50%;
  height: 50%;

  @media (--md-viewport) {
    width: 75%;
    height: 75%;
  }

  @media (--lg-viewport) {
    width: 100%;
    height: 100%;
  }
}