/* Animations */

@custom-media --h-mobile-viewport (max-height: 414px) and (max-width: 1024px);
@custom-media --h-desktop-viewport (min-height: 720px) and (min-width: 1280px);
@custom-media --xs-viewport (min-width: 376px);
@custom-media --sm-viewport (min-width: 480px);
@custom-media --md-viewport (min-width: 860px);
@custom-media --lg-viewport (min-width: 1024px);
@custom-media --xlg-viewport (min-width: 1280px);
@custom-media --2xlg-viewport (min-width: 1920px);

.RS-animation {
  opacity: 0.01;
  transition-timing-function: var(--RS-default-transition);
  transition-duration: var(--RS-anim-duration-lg), var(--RS-anim-duration-xlg);
  transition-property: opacity, transform;
  transform: translate3d(0, 0, 0) rotate(var(--RS-rotate-firefox-fix));
  transform-style: preserve-3d;
}

.RS-animation__slow{
  transition-duration: var(--RS-anim-duration-lg), var(--RS-anim-duration-2xlg);
}

.RS-animation.RS-animation--enter {
  opacity: 1;
  transform: translate3d(0, 0, 0) scaleY(1) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__text-to-top {
  transform: translate3d(0, 32px, 0) scaleY(.96) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__text-to-bottom {
  transform: translate3d(0, -32px, 0) scaleY(.96) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__text-to-left {
  transform: translate3d(13px, 0, 0) scaleY(.96) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__text-to-right {
  transform: translate3d(-8px, 0, 0) scaleY(.96) rotate(var(--RS-rotate-firefox-fix));
}


.RS-animation__image-to-right {
  transform: translate3d(var(--RS-space-24), 0, 0) scaleY(.995) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__image-to-left {
  transform: translate3d(var(--RS-space-24-n), 0, 0) scaleY(.995) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__image-to-top {
  transform: translate3d(0, var(--RS-space-16), 0) scaleY(.995) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__image-to-bottom {
  transform: translate3d(0, var(--RS-space-16-n), 0) scaleY(.995) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__opacity{
  opacity: 0.01;
  transform: unset;
  transform-style: unset;
}

.RS-animation.RS-animation__opacity.RS-animation--enter{
  transform: unset;
}

.RS-animation__background-decoration--hero-first {
  transform: translate3d(0, 0, 0) rotate(var(--RS-rotate-firefox-fix));
  animation: animBgDecorHero1 20s linear infinite;
  /* transform-origin: top; */
  opacity: 0;
}

@keyframes animBgDecorHero1 {
  0%,2.5%,97.5%,100%{
    opacity: 0;
  }
  10%,90%{
    opacity: 1;
  }
  97.5%{
    transform: translateX(-80%) translateY(-70%) scale(.9) rotateZ(-60deg);
  }
}


.RS-animation__background-decoration--hero-second {
  transform: translate3d(0, 0, 0) rotate(var(--RS-rotate-firefox-fix));
  animation: animBgDecorHero2 18s linear infinite;
  /* transform-origin: top; */
  opacity: 0;
}

@keyframes animBgDecorHero2 {
  0%,2.5%,97.5%,100%{
    opacity: 0;
  }
  10%,90%{
    opacity: 1;
  }
  97.5%{
    transform: translateX(80%) translateY(70%) scale(.9) rotateZ(60deg);
  }
}



.RS-animation__background-decoration--about-project {
  transform: translate3d(0, 0, 0) rotate(var(--RS-rotate-firefox-fix));
  animation: animBgDecorAboutProject 19s linear infinite;
  /* transform-origin: top; */
  opacity: 0;
}

@keyframes animBgDecorAboutProject {
  0%,2.5%,97.5%,100%{
    opacity: 0;
  }
  10%,90%{
    opacity: 1;
  }
  97.5%{
    transform: translateX(-50%) translateY(-160%) scale(1.2) rotateZ(-90deg);
  }
}


.RS-animation__background-decoration--footer {
  transform: translate3d(0, 0, 0) rotate(var(--RS-rotate-firefox-fix));
  animation: animBgDecorFooter 20s linear infinite;
  opacity: 0;
}

@keyframes animBgDecorFooter {
  0%,2.5%,97.5%,100%{
    opacity: 0;
  }
  10%,90%{
    opacity: 1;
  }
  97.5%{
    transform: translateX(80%) translateY(-70%) scale(.9) rotateZ(-67deg);
  }
}

/* .RS-stagger--1{
  transition-delay: calc(var(--RS-anim-delay) * 1);
}

.RS-stagger--2{
  transition-delay: calc(var(--RS-anim-delay) * 2);
}

.RS-stagger--3{
  transition-delay: calc(var(--RS-anim-delay) * 3);
}

.RS-stagger--4{
  transition-delay: calc(var(--RS-anim-delay) * 4);
}

.RS-stagger--5{
  transition-delay: calc(var(--RS-anim-delay) * 5);
}

.RS-stagger--6{
  transition-delay: calc(var(--RS-anim-delay) * 6);
}

.RS-stagger--7{
  transition-delay: calc(var(--RS-anim-delay) * 7);
}

.RS-stagger--8{
  transition-delay: calc(var(--RS-anim-delay) * 8);
} */

.RS-looped-animation{
  animation-play-state: paused!important;
  backface-visibility: hidden;
  will-change: transform;
}

.RS-looped-animation--running{
  animation-play-state: running!important;
}