/* Global */

@custom-media --h-mobile-viewport (max-height: 414px) and (max-width: 1024px);
@custom-media --h-desktop-viewport (min-height: 720px) and (min-width: 1280px);
@custom-media --xs-viewport (min-width: 376px);
@custom-media --sm-viewport (min-width: 480px);
@custom-media --md-viewport (min-width: 860px);
@custom-media --lg-viewport (min-width: 1024px);
@custom-media --xlg-viewport (min-width: 1280px);
@custom-media --2xlg-viewport (min-width: 1920px);

html {
  font-size: 100%;
}

body {
  overflow-x: hidden;
  overflow-y: auto;
  font-family: "PublicSans-Variable", system-ui, sans-serif;
  /* font-size: 16px; */
  font-size: var(--RS-font-size-p-desktop);
  font-weight: var(--RS-font-weight-variable-regular);
  line-height: 1;
  color: var(--RS-black-color);
  letter-spacing: -0.01em;
  background-color: var(--RS-white-color);
  transition: none;
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
}

body > main {
  width: 100%;
  /* min-height: 1px; */
  min-height: 90vh;
  overflow: hidden;
}

a{
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

/* @view-transition {
  navigation: auto;
} */

@media screen and (min--moz-device-pixel-ratio:0) {

  /* Visually neglectible rotation added to 'modest' animations which normally result in shaking/jumping effect, FF only */
  :root{
      --RS-rotate-firefox-fix: 0.03deg;
  }

  /* Invisible outline added to prevent skewed/rotated edges to be pixelated/un-antialiased */
  svg,
  path{
     outline: 1px solid rgba(255,255,255,0);
  }

  html{
      scrollbar-width: thin;
  }

  ::-moz-selection {
    color: var(--RS-light-grey-color);
    background: var(--RS-black-color);
  }
}

::selection {
  color: var(--RS-light-grey-color);
  background: var(--RS-black-color);
}

body::-webkit-scrollbar{
  width: var(--RS-space-6);
  height: var(--RS-space-4);
  background-color: var(--RS-white-color);
}

body::-webkit-scrollbar-thumb {
  background: var(--RS-black-color);
  border-radius: 4px;
}

[role="button"] {
  cursor: pointer;
}

svg path,
svg g{
  transform-box: fill-box;
  transform-origin: center;
}

img,
video{
  width: 100%;
  height: auto;
  outline: 1px solid transparent;
}

picture,
img{
  border-radius: inherit;
  user-select: none;
}


ul{
  list-style: none;
}

.RS-photo--square img{
  aspect-ratio: 1/1;
}

.RS-photo--vertical img{
  aspect-ratio: 0.665/1;
}

.RS-photo--horizontal img{
  aspect-ratio: 1.5/1;
}

/* .RS-photo--branded {
  transition: opacity var(--RS-anim-duration-lg) var(--RS-default-transition);
  display: block;
}

.RS-photo--branded :before, 
.RS-photo--branded :after {
  content: "";
  background-color: var(--RS-brand-color);
  border-radius: inherit;
  mix-blend-mode: hard-light;
  opacity: .5;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-mask-image: linear-gradient(45deg, #000, #0000 65%);
  mask-image: linear-gradient(45deg, #000, #0000 65%);
}

.RS-photo--branded :before{
  mix-blend-mode: unset;
  opacity: .5;
  background-color: var(--RS-brand-color);
} */