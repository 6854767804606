/* hero */

@custom-media --h-mobile-viewport (max-height: 414px) and (max-width: 1024px);
@custom-media --h-desktop-viewport (min-height: 720px) and (min-width: 1280px);
@custom-media --xs-viewport (min-width: 376px);
@custom-media --sm-viewport (min-width: 480px);
@custom-media --md-viewport (min-width: 860px);
@custom-media --lg-viewport (min-width: 1024px);
@custom-media --xlg-viewport (min-width: 1280px);
@custom-media --2xlg-viewport (min-width: 1920px);

.RS-hero {
  position: relative;
  z-index: 1;
  padding: 15.5vh var(--RS-space-24) 2.5vh;
  display: flex;
  align-items: center;
  /* height: 90vh; */

  @media (--h-mobile-viewport) {
    height: auto;
    /* min-height: 100vh; */
  }

  @media (--md-viewport) {
    padding: 12vh var(--RS-space-24) 4vh;
  }

  @media (--lg-viewport) {
    /* height: 100vh; */
    min-height: 80vh;
    padding: 12vh var(--RS-space-32) var(--RS-space-16);
    margin: 0 0 0;
  }
}

.RS-hero--confirmation-early-access{
  @media (--lg-viewport) {
    min-height: 85vh;
  }
}

.RS-hero__container {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: var(--RS-col-8);
  min-height: 100%;
  margin: 0 auto;
  height: 100%;

  @media (--lg-viewport) {
    /* flex-direction: row-reverse; */
  }

}

