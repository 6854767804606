
@custom-media --h-mobile-viewport (max-height: 414px) and (max-width: 1024px);
@custom-media --h-desktop-viewport (min-height: 720px) and (min-width: 1280px);
@custom-media --xs-viewport (min-width: 376px);
@custom-media --sm-viewport (min-width: 480px);
@custom-media --md-viewport (min-width: 860px);
@custom-media --lg-viewport (min-width: 1024px);
@custom-media --xlg-viewport (min-width: 1280px);
@custom-media --2xlg-viewport (min-width: 1920px);

.RS-button {
  appearance: none;
  background: transparent;
  border: 0;
  color: var(--RS-white-color);
  cursor: pointer;
  font: inherit;
  font-weight: var(--RS-font-weight-variable-regular);
  font-size: var(--RS-font-size-button);
  line-height: 1;
  padding: calc(var(--RS-space-12) + var(--RS-space-2)) calc(var(--RS-space-32) + var(--RS-space-2)) var(--RS-space-12) calc(var(--RS-space-32) + var(--RS-space-2));
  position: relative;
  /* transition: filter var(--RS-anim-duration-md) var(--RS-bounce-transition); */
  user-select: none;
  display: inline-flex;
  -webkit-tap-highlight-color: transparent;

  @media (--lg-viewport) {
    font-size: var(--RS-font-size-button-desktop);
  }
}

.RS-button:focus{
  outline: unset;
}

.RS-button__content {
  display: block;
  position: relative;
  transition: transform var(--RS-anim-duration-md) var(--RS-bounce-transition);
}

.RS-button__shape {
  fill: #004940;
  position: absolute;
  inset: 0;
  inline-size: 100%;
  block-size: 100%;
  overflow: visible;
  transition: transform var(--RS-anim-duration-md) var(--RS-bounce-transition);
  filter: url('#RS-button-svg-filter');
}

.RS-button__path {
  transition: d var(--RS-anim-duration-md) var(--RS-bounce-transition);
  /* fill: var(--RS-brand-color); */
  fill: var(--RS-cta-color);
  /* fill: var(--RS-black-color); */
  d: path("M0,-5 C30,10 70,10 100,-5 C100,30 100,70 100,100 C70,110 30,110 0,100 C0,70 0,30 0,0 z"); 
}

.RS-button--brand .RS-button__path{
  fill: var(--RS-brand-color);
}

.RS-button:focus .RS-button__path, 
.RS-button:hover .RS-button__path {
  d: path("M0,0 C0,-7.5 100,-7.5 100,0 C100,0 100,100 100,100 C100,107.5 0,107.5 0,100 C0,100 -0,0 0,0 z");
}

.RS-button:focus,
.RS-button.RS-button--active,
.RS-button:hover {
  filter: brightness(1.1);
}

.RS-button:focus .RS-button__shape,
.RS-button:hover .RS-button__shape{
  transform: scaleX(.98);
}

.RS-button:focus .RS-button__content,
.RS-button:hover .RS-button__content{
  transform: translateY(-2px);
}


.RS-button--inverted{
  color: var(--RS-black-color);
  background-color: var(--RS-light-grey-color);
}

.RS-button--inverted.RS-button--active,
.RS-button--inverted:hover{
  color: var(--RS-black-color);
  background-color: var(--RS-white-color);
}


.RS-button--small{
  padding: var(--RS-space-12) var(--RS-space-16);
  min-width: unset;
}

.RS-button--ghost{
  background-color: unset;
  color: var(--RS-black-color);
  /* border: 1px solid var(--RS-black-color); */
  border-radius: var(--RS-space-32);
  /* font-weight: var(--RS-font-weight-variable-bold); */
}

.RS-button--ghost.RS-button--active,
.RS-button--ghost:hover{
  color: var(--RS-black-color);
  /* background-color: var(--RS-white-color); */
}

.RS-button > svg.fa-arrow-right {
  margin: 0 0 0 var(--RS-space-16);
  font-size: 24px;
  font-size: clamp(1.25rem, 1.1994rem + 0.2532vw, 1.5rem);
  line-height: 1;
  transition: transform var(--RS-anim-duration-md) var(--RS-default-transition);
  transform: translate3d(0, 0, 0);
}


.RS-button.RS-button--nav{
  width: auto;
  max-width: none;
  background: unset;
  color: var(--RS-black-color);
  font-weight: var(--RS-font-weight-variable-regular);
  padding: 0 0 var(--RS-space-4) 0;
  font-size: var(--RS-font-size-mobile-nav);

  @media (--md-viewport) {
    color: var(--RS-white-color);
    padding: var(--RS-space-12) calc(var(--RS-space-24) + var(--RS-space-2)) calc(var(--RS-space-12) - var(--RS-space-2)) calc(var(--RS-space-24) + var(--RS-space-2));
    opacity: 1;
    font-size: var(--RS-font-size-top-nav);
  
  }
}

.RS-button.RS-button--nav .RS-button__shape{
  display: none;
  @media (--md-viewport) {
    display: initial;
  }
}

.RS-button.RS-button--nav.RS-button--active,
.RS-button.RS-button--nav:hover {
  @media (--md-viewport) {
    color: var(--RS-white-color);
  }
}


.RS-button + .RS-disclaimer{
  margin: var(--RS-space-16) 0 0 0;
}

.RS-button__icon > svg{
  height: var(--RS-space-12);
}

.RS-button__icon > svg path{
  fill: var(--RS-black-color);
  /* opacity: .67; */
}