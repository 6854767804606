/* Top Bar */

@custom-media --h-mobile-viewport (max-height: 414px) and (max-width: 1024px);
@custom-media --h-desktop-viewport (min-height: 720px) and (min-width: 1280px);
@custom-media --xs-viewport (min-width: 376px);
@custom-media --sm-viewport (min-width: 480px);
@custom-media --md-viewport (min-width: 860px);
@custom-media --lg-viewport (min-width: 1024px);
@custom-media --xlg-viewport (min-width: 1280px);
@custom-media --2xlg-viewport (min-width: 1920px);



.RS-marquee-wrapper{

  padding: var(--RS-space-48) 0;
  /* width: 100%; */
  width: 100%;
  cursor: pointer;
  /* margin-left: var(--RS-space-24-n); */
  position: relative;

  
  @media (--lg-viewport) {
    padding: var(--RS-space-64) 0;
    width: 100%;
    margin-left: 0;
    /* mask-image: linear-gradient(90deg, transparent 0%, rgba(0, 0, 0, 1) 15% 85%, transparent 100%); */
  }
}

.RS-marquee__item svg{
  height: var(--RS-space-32);
  margin: 0 auto;
}

.RS-marquee{
  width: var(--RS-col-8);
}

.RS-marquee--narrow{
  width: var(--RS-col-6);
}

.RS-marquee--facts{
  @media (--lg-viewport) {
    mask-image: linear-gradient(90deg, transparent 0%, rgba(0, 0, 0, 1) 15% 85%, transparent 100%);
  }
}

.RS-marquee--personas{
  @media (--lg-viewport) {
    /* mask-image: linear-gradient(90deg, rgba(0, 0, 0, 1) 85%, transparent 95% 100%); */
    mask-image: linear-gradient(90deg, transparent 0%, rgba(0, 0, 0, 1) 15% 85%, transparent 100%);

  }
}

.RS-hero-marquee{
  margin-bottom: var(--RS-space-8);
}

.RS-hero-marquee__inner{
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: space-between; */
  height: 340px;
  width: 100%;
  /* max-width: calc(var(--RS-space-256) + var(--RS-space-128) + var(--RS-space-64) + var(--RS-space-64)); */
  /* max-width: calc(var(--RS-space-256) + var(--RS-space-128) + var(--RS-space-64) + var(--RS-space-96)); */
  max-width: calc(var(--RS-space-256) + var(--RS-space-128) + var(--RS-space-64) + var(--RS-space-32));
  margin: 0 auto 0 auto;  /* overflow: hidden; */
  /* gap: calc(var(--RS-space-64) + var(--RS-space-8)); */
  position: relative;

  @media (--md-viewport) {
    flex-direction: column;
    /* gap: var(--RS-space-64); */
    /* justify-content: space-between; */
    padding: var(--RS-space-96) var(--RS-space-32);
  }
}



.RS-hero-marquee__item{
  width: calc(50% - var(--RS-space-8));
  /* max-width: calc(50% - var(--RS-space-8)); */
  /* max-width: 80%; */
  width: 70%;
  /* height: 180px; */
  height: auto;
  /* margin-bottom: 16px; */
  position: absolute;
  bottom: var(--RS-space-48);
  
  display: flex;
  align-items: center;
}



.RS-hero-marquee__item > div{
  display: inline-flex;
  flex-direction: column;
  padding: calc(var(--RS-space-16) - var(--RS-space-2));
  width: 100%;
  gap: var(--RS-space-6);
  position: relative;
  flex: 0 0 auto;

  @media (--md-viewport) {
    padding: calc(var(--RS-space-16) + var(--RS-space-2));
  }
}


/* .RS-hero-marquee__item:nth-of-type(even){ */
.RS-hero-marquee__item:nth-of-type(odd){
  /* margin-right: auto; */
  left: 0;
}

/* .RS-hero-marquee__item:nth-of-type(even) > div{ */
.RS-hero-marquee__item:nth-of-type(odd) > div{
  background-color: var(--RS-accent-color-primary);
  /* background-color: var(--RS-accent-color-secondary); */
  border-radius: var(--RS-space-12) var(--RS-space-12) var(--RS-space-12) var(--RS-space-2);
}

/* .RS-hero-marquee__item:nth-of-type(odd){ */
.RS-hero-marquee__item:nth-of-type(even){
  /* margin-left: auto; */
  right: 0;
}

/* .RS-hero-marquee__item:nth-of-type(odd) > div{ */
.RS-hero-marquee__item:nth-of-type(even) > div{
  background-color: var(--RS-dark-grey-color);
  background-color: var(--RS-graphite-color);
  border-radius: var(--RS-space-12) var(--RS-space-12) var(--RS-space-2) var(--RS-space-12);
  color: var(--RS-white-color);
  /* transform: translateY(50%); */
}

.RS-hero-marquee__item > div > p{
  z-index: 1;
}

.RS-hero-marquee__item > div > p{
  /* font-size: var(--RS-font-size-label); */
  font-size: var(--RS-font-size-label);
  font-weight: var(--RS-font-weight-variable-regular);
  line-height: var(--RS-line-height-lg);

  @media (--lg-viewport) {
    /* font-size: var(--RS-font-size-label-desktop); */
    font-size: var(--RS-font-size-p-desktop);
    line-height: var(--RS-line-height-lg);
  }
}


.RS-hero-marquee__item > div > picture{
  position: absolute;
  bottom: 0;
  left: calc(0% - var(--RS-space-48));
  transform: translate(-25%, 0%);
  /* transform: translate(0%, -67%); */
  width: var(--RS-space-48);
  height: var(--RS-space-48);
  aspect-ratio: 1/1;
  border-radius: 50%;
  z-index: 0;
  display: none;

  @media (--md-viewport) {
    display: block;
  }

  @media (--lg-viewport) {
    display: none;
  }

  @media (--xlg-viewport) {
    display: block;
  }

}
@keyframes animHeroMarqueeImg {
  0% {
    opacity: .7;
  }
  50%{
    opacity: 1;
  }
  100% {
    opacity: .7;
  }
}

.RS-hero-marquee__reaction{
  position: absolute;
  right: var(--RS-space-16);
  bottom: var(--RS-space-12-n);
  width: var(--RS-space-24);
  height: var(--RS-space-24);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(--RS-white-color);
  box-shadow: inset 0 0 0 1px var(--RS-dark-light-color);
  opacity: 0;
  transform: translateY(50%) translateX(-300%) scale(.34,.34);
  transition: opacity var(--RS-anim-duration-sm) var(--RS-default-transition), transform var(--RS-anim-duration-xlg) var(--RS-bounce-transition);
}

.RS-hero-marquee__reaction > svg{
  width: calc(var(--RS-space-24) - var(--RS-space-6));
  height: calc(var(--RS-space-24) - var(--RS-space-6));
}

.RS-hero-marquee__reaction--visible{
  opacity: 1;
  transform: translateY(0) translateX(0) scale(1,1);
}

.RS-marquee-nav{
  position: absolute;
  top: 0;
  height: 100%;
  display: none;
  color: var(--RS-brand-color);
  z-index: 2;
  display: none;
  padding: var(--RS-space-12);

  @media (--lg-viewport) {
    display: flex;
    align-items: center;
  }
}

.RS-marquee-nav--prev{
  left: var(--RS-space-16-n);
  /* left: 0; */
}

.RS-marquee-nav--next{
  right: var(--RS-space-16-n);
  /* right: 0; */
}


.RS-marquee-nav svg{
  /* width: var(--RS-space-12); */
  width: calc(var(--RS-space-12) - var(--RS-space-2));
}

.RS-marquee__source{
  font-size: var(--RS-font-size-disclaimer);
  margin-top: auto;
  display: flex;
  gap: var(--RS-space-4);
}

.RS-marquee__source a{
  color: var(--RS-dark-grey-color);
  font-weight: var(--RS-font-weight-variable-bold);
}

.RS-marquee__source a:hover,
.RS-marquee__source a:focus{
  /* text-decoration: underline; */
  /* opacity: .67; */
  color: var(--RS-brand-color);
}

.RS-marquee__item .RS-list__tile{
  height: 100%;
  /* justify-content: space-around; */
}