/* Footer */

@custom-media --h-mobile-viewport (max-height: 414px) and (max-width: 1024px);
@custom-media --h-desktop-viewport (min-height: 720px) and (min-width: 1280px);
@custom-media --xs-viewport (min-width: 376px);
@custom-media --sm-viewport (min-width: 480px);
@custom-media --md-viewport (min-width: 860px);
@custom-media --lg-viewport (min-width: 1024px);
@custom-media --xlg-viewport (min-width: 1280px);
@custom-media --2xlg-viewport (min-width: 1920px);

.RS-footer {
  padding: var(--RS-space-64) var(--RS-space-24) var(--RS-space-32);
  /* font-weight: var(--RS-font-weight-variable-bold); */
  position: relative;
  overflow: hidden;

  @media (--md-viewport) {
    padding: 0 var(--RS-space-32) var(--RS-space-32) var(--RS-space-32);
  }
}

.RS-footer__container {
  max-width: var(--RS-col-8);
  margin: 0 auto;
}

.RS-footer__copyright {
  font-size: var(--RS-font-size-disclaimer);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: var(--RS-font-weight-variable-bold);

  @media (--lg-viewport) {
    font-size: var(--RS-font-size-disclaimer-desktop);
  }
}

.RS-footer__navigation {
  
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: var(--RS-space-8) 0;

  @media (--md-viewport) {
    margin: var(--RS-space-48) 0;
    flex-direction: row;
  }

}

.RS-footer .RS-link{
  font-weight: var(--RS-font-weight-variable-bold);
}



.RS-footer .RS-divider{
  margin: 0 var(--RS-space-16);
  margin-top: var(--RS-space-8);
  width: var(--RS-space-6);
  height: var(--RS-space-6);
  border-radius: 50%;
  display: flex;

  @media (--md-viewport) {
    margin-bottom: var(--RS-space-2);
    display: inline-flex;
    background: var(--RS-brand-color);
  }
}

.RS-footer__col {
  font-size: var(--RS-font-size-label);
  line-height: var(--RS-line-height-xlg);
  margin-bottom: var(--RS-space-48);
  /* display: flex;
  gap: var(--RS-space-12);
  flex-direction: column; */

  @media (--md-viewport) {
    margin-bottom: 0;
    display: block;
  }

  @media (--xlg-viewport) {
    font-size: var(--RS-font-size-label-desktop);
  }
}

.RS-footer__container > .RS-footer__col{
  margin: 0;
}

.RS-footer__col .RS-link svg{
  margin-left: var(--RS-space-4);
}

.RS-footer__disclaimer {
  font-size: var(--RS-font-size-disclaimer);
  font-weight: var(--RS-font-weight-variable-regular);
  position: absolute;
  transform: translateY(calc(-100% - var(--RS-space-2)));

  @media (--lg-viewport) {
    font-size: var(--RS-font-size-disclaimer-desktop);
    transform: translateY(calc(-100% - var(--RS-space-4)));
  }
}

.RS-footer__decor{
  content: '';
  display: none;
  width: calc(var(--RS-space-256) + var(--RS-space-4) + var(--RS-space-32));
  height: calc(var(--RS-space-256) - 0px);
  border-radius: var(--RS-space-64);
  top: 50%;
  right: 0;
  background: var(--RS-accent-color-primary);
  transform: translateX(-50%) skewX(8deg) rotateZ(15deg);
  position: absolute;
  z-index: -1;

  @media (--md-viewport) {
    display: block;
  }
}
