/* Variables */

:root {
  /* Spaces */
  --RS-space-1: 1px;
  --RS-space-2: 2px;
  --RS-space-4: 4px;
  --RS-space-6: 6px;
  --RS-space-8: 8px;
  --RS-space-12: 12px;
  --RS-space-16: 16px;
  --RS-space-24: 24px;
  --RS-space-32: 32px;
  --RS-space-48: 48px;
  --RS-space-64: 64px;
  --RS-space-96: 96px;
  --RS-space-128: 128px;
  --RS-space-192: 192px;
  --RS-space-256: 256px;

  --RS-space-1-n: -1px;
  --RS-space-2-n: -2px;
  --RS-space-4-n: -4px;
  --RS-space-6-n: -6px;
  --RS-space-8-n: -8px;
  --RS-space-12-n: -12px;
  --RS-space-16-n: -16px;
  --RS-space-24-n: -24px;
  --RS-space-32-n: -32px;
  --RS-space-48-n: -48px;
  --RS-space-64-n: -64px;
  --RS-space-96-n: -96px;
  --RS-space-128-n: -128px;
  --RS-space-192-n: -192px;
  --RS-space-256-n: -256px;

  /* Grid */
  --RS-col-1: 160px;
  --RS-col-2: 320px;
  --RS-col-3: 480px;
  --RS-col-4: 640px;
  --RS-col-5: 800px;
  --RS-col-6: 960px;
  --RS-col-7: 1120px;
  --RS-col-8: 1280px;

  /* Main colors */
  --RS-white-color: #ffffff;
  --RS-black-color: #0f1113;
  --RS-light-grey-color: #dddddd;
  --RS-dark-grey-color: #88837a;
  --RS-dark-grey-color-wcag: #5B5853;
  --RS-graphite-color: #272d34;
  --RS-brand-color: #0655a5;
  --RS-accent-color-primary: #e1f0f4;
  --RS-accent-color-secondary: #ffe9ca;
  --RS-cta-color: #A68A07;
  --RS-cta-color: #00836f;


/* Motion */
  --RS-anim-delay: 50ms;
  --RS-default-transition: cubic-bezier(0, 0.86, 1, 1);
  --RS-bounce-transition: cubic-bezier(0.175, 0.885, 0.320, 1.275);
  --RS-anim-duration-xs: 75ms;
  --RS-anim-duration-sm: 150ms;
  --RS-anim-duration-md: 225ms;
  --RS-anim-duration-lg: 350ms;
  --RS-anim-duration-xlg: 600ms;
  --RS-anim-duration-2xlg: 1200ms;
  --RS-anim-hero-artwork-delay: 750ms;
  --RS-anim-full-rotate-duration: 90000ms;

  /* Typography */
  --RS-line-height-md: 1.21;
  --RS-line-height-lg: 1.34;
  --RS-line-height-xlg: 1.55;

  --RS-font-size-h1: clamp(1.375rem, 1.0244rem + 1.5584vw, 2.875rem); /* 22 - 46px, minmax 360 - 1900px xxxx */ 
  --RS-font-size-h1-desktop: clamp(2.625rem, 2.1089rem + 0.6452vw, 2.875rem);  /* 42 - 46px, minmax 1280 - 1900px */
  --RS-font-size-h1--hero: clamp(1.8rem, 1.37925rem + 1.87011vw, 3.6rem); /* 28.8 - 57.6px, minmax 360 - 1900px xxxxxxx */
  --RS-font-size-h1--hero-desktop: clamp(3.2625rem, 2.56572rem + 0.87093vw, 3.6rem); /* 52.2px - 57.6px, minmax 1280 - 1900px */
  --RS-font-size-h2: clamp(0.875rem, 0.8166rem + 0.2597vw, 1.125rem); /* 14 - 18px, minmax 360 - 1900px xxxxxx */
  --RS-font-size-h2-desktop: clamp(1rem, 0.7419rem + 0.3226vw, 1.125rem); /* 16 - 18px, minmax 1280 - 1900px */
  --RS-font-size-h3: clamp(1.03125rem, 0.9217rem + 0.487vw, 1.5rem); /* 16.5 - 24px, minmax 360 - 1900px xxxxx */
  --RS-font-size-h3-desktop: clamp(1.375rem, 1.1169rem + 0.3226vw, 1.5rem);  /* 22 - 24px, minmax 1280 - 1900px */
  --RS-font-size-top-nav: clamp(0.8125rem, 0.7216rem + 0.1818vw, 0.9375rem); /* 14 - 15px, minmax 800 - 1900px */
  --RS-font-size-mobile-nav: clamp(1.75rem, 1.4537rem + 1.4815vw, 2.25rem); /* 28 - 36px, minmax 320 - 860px */
  --RS-font-size-p: clamp(0.9375rem, 0.9083rem + 0.1299vw, 1.0625rem); /* 15 - 17px, minmax 360 - 1900px xxxxxx */
  --RS-font-size-p-desktop: clamp(0.9375rem, 0.744rem + 0.2419vw, 1.03125rem); /* 16 - 17px, minmax 1280 - 1900px */
  --RS-font-size-label: clamp(.875rem, .8531rem + .0974vw, .96875rem); /* 14 - 15.5px, minmax 360 - 1900px xxxxx */
  --RS-font-size-label-desktop: clamp(0.9375rem, 0.8085rem + 0.1613vw, 1rem); /* 15 - 16px, minmax 1280 - 1900px */
  --RS-font-size-disclaimer: clamp(0.6875rem, 0.6583rem + 0.1299vw, 0.8125rem);/* 11 - 13px, minmax 360 - 1900px */
  --RS-font-size-disclaimer-desktop: clamp(0.75rem, 0.621rem + 0.1613vw, 0.8125rem); /* 12 - 13px, minmax 1280 - 1900px */
  --RS-font-size-button: clamp(1rem, 0.9708rem + 0.1299vw, 1.125rem); /* 16 - 18px, minmax 320 - 1900px */
  --RS-font-size-button-desktop: clamp(1.0625rem, 0.9335rem + 0.1613vw, 1.125rem);/* 17 - 18px, minmax 1280 - 1900px */

  --RS-font-weight-variable-light: 350;
  --RS-font-weight-variable-regular: 450;
  --RS-font-weight-variable-bold: 650;

  /* Misc. */
  --RS-brand-bg-xy: calc(var(--RS-space-24) - var(--RS-space-8));
  --RS-rotate-firefox-fix: 0deg;

}