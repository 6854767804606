*, :before, :after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html, body {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  line-height: 1;
}

img, picture, video, canvas, svg {
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

a {
  color: inherit;
  text-decoration: none;
}

:root {
  --RS-space-1: 1px;
  --RS-space-2: 2px;
  --RS-space-4: 4px;
  --RS-space-6: 6px;
  --RS-space-8: 8px;
  --RS-space-12: 12px;
  --RS-space-16: 16px;
  --RS-space-24: 24px;
  --RS-space-32: 32px;
  --RS-space-48: 48px;
  --RS-space-64: 64px;
  --RS-space-96: 96px;
  --RS-space-128: 128px;
  --RS-space-192: 192px;
  --RS-space-256: 256px;
  --RS-space-1-n: -1px;
  --RS-space-2-n: -2px;
  --RS-space-4-n: -4px;
  --RS-space-6-n: -6px;
  --RS-space-8-n: -8px;
  --RS-space-12-n: -12px;
  --RS-space-16-n: -16px;
  --RS-space-24-n: -24px;
  --RS-space-32-n: -32px;
  --RS-space-48-n: -48px;
  --RS-space-64-n: -64px;
  --RS-space-96-n: -96px;
  --RS-space-128-n: -128px;
  --RS-space-192-n: -192px;
  --RS-space-256-n: -256px;
  --RS-col-1: 160px;
  --RS-col-2: 320px;
  --RS-col-3: 480px;
  --RS-col-4: 640px;
  --RS-col-5: 800px;
  --RS-col-6: 960px;
  --RS-col-7: 1120px;
  --RS-col-8: 1280px;
  --RS-white-color: #fff;
  --RS-black-color: #0f1113;
  --RS-light-grey-color: #ddd;
  --RS-dark-grey-color: #88837a;
  --RS-dark-grey-color-wcag: #5b5853;
  --RS-graphite-color: #272d34;
  --RS-brand-color: #0655a5;
  --RS-accent-color-primary: #e1f0f4;
  --RS-accent-color-secondary: #ffe9ca;
  --RS-cta-color: #a68a07;
  --RS-cta-color: #00836f;
  --RS-anim-delay: 50ms;
  --RS-default-transition: cubic-bezier(0, .86, 1, 1);
  --RS-bounce-transition: cubic-bezier(.175, .885, .32, 1.275);
  --RS-anim-duration-xs: 75ms;
  --RS-anim-duration-sm: .15s;
  --RS-anim-duration-md: .225s;
  --RS-anim-duration-lg: .35s;
  --RS-anim-duration-xlg: .6s;
  --RS-anim-duration-2xlg: 1.2s;
  --RS-anim-hero-artwork-delay: .75s;
  --RS-anim-full-rotate-duration: 90s;
  --RS-line-height-md: 1.21;
  --RS-line-height-lg: 1.34;
  --RS-line-height-xlg: 1.55;
  --RS-font-size-h1: clamp(1.375rem, 1.0244rem + 1.5584vw, 2.875rem);
  --RS-font-size-h1-desktop: clamp(2.625rem, 2.1089rem + .6452vw, 2.875rem);
  --RS-font-size-h1--hero: clamp(1.8rem, 1.37925rem + 1.87011vw, 3.6rem);
  --RS-font-size-h1--hero-desktop: clamp(3.2625rem, 2.56572rem + .87093vw, 3.6rem);
  --RS-font-size-h2: clamp(.875rem, .8166rem + .2597vw, 1.125rem);
  --RS-font-size-h2-desktop: clamp(1rem, .7419rem + .3226vw, 1.125rem);
  --RS-font-size-h3: clamp(1.03125rem, .9217rem + .487vw, 1.5rem);
  --RS-font-size-h3-desktop: clamp(1.375rem, 1.1169rem + .3226vw, 1.5rem);
  --RS-font-size-top-nav: clamp(.8125rem, .7216rem + .1818vw, .9375rem);
  --RS-font-size-mobile-nav: clamp(1.75rem, 1.4537rem + 1.4815vw, 2.25rem);
  --RS-font-size-p: clamp(.9375rem, .9083rem + .1299vw, 1.0625rem);
  --RS-font-size-p-desktop: clamp(.9375rem, .744rem + .2419vw, 1.03125rem);
  --RS-font-size-label: clamp(.875rem, .8531rem + .0974vw, .96875rem);
  --RS-font-size-label-desktop: clamp(.9375rem, .8085rem + .1613vw, 1rem);
  --RS-font-size-disclaimer: clamp(.6875rem, .6583rem + .1299vw, .8125rem);
  --RS-font-size-disclaimer-desktop: clamp(.75rem, .621rem + .1613vw, .8125rem);
  --RS-font-size-button: clamp(1rem, .9708rem + .1299vw, 1.125rem);
  --RS-font-size-button-desktop: clamp(1.0625rem, .9335rem + .1613vw, 1.125rem);
  --RS-font-weight-variable-light: 350;
  --RS-font-weight-variable-regular: 450;
  --RS-font-weight-variable-bold: 650;
  --RS-brand-bg-xy: calc(var(--RS-space-24)  - var(--RS-space-8));
  --RS-rotate-firefox-fix: 0deg;
}

@font-face {
  font-family: PublicSans-Variable;
  src: url("../PublicSans-Variable.8b34fb1f.woff2") format("woff2"), url("../PublicSans-Variable.5c684e4f.woff") format("woff"), url("../PublicSans-Variable.6ce2efbf.ttf") format("truetype");
  font-weight: 100 1000;
  font-stretch: 75% 125%;
  font-display: swap;
}

@font-face {
  font-family: PlayfairDisplay-Variable;
  src: url("../PlayfairDisplay-Variable.25511d37.woff2") format("woff2"), url("../PlayfairDisplay-Variable.ad0412de.woff") format("woff"), url("../PlayfairDisplay-Variable.e8c149aa.ttf") format("truetype");
  font-weight: 100 1000;
  font-stretch: 75% 125%;
  font-display: swap;
}

@font-face {
  font-family: PlayfairDisplay-Italic-Variable;
  src: url("../PlayfairDisplay-Italic-Variable.4518ae13.woff2") format("woff2"), url("../PlayfairDisplay-Italic-Variable.9df014ee.woff") format("woff"), url("../PlayfairDisplay-Italic-Variable.0d9029d2.ttf") format("truetype");
  font-weight: 100 1000;
  font-stretch: 75% 125%;
  font-display: swap;
}

p, h1, h2, h3, h4, h5, h6 {
  font-weight: var(--RS-font-weight-variable-regular);
}

.RS-simple-link {
  text-decoration: underline;
  transition: color .35s;
}

.RS-simple-link:hover {
  color: var(--RS-primary-button-color);
}

.RS-link {
  color: inherit;
  padding-bottom: var(--RS-space-2);
  text-decoration: none;
  position: relative;
}

.RS-link:before {
  content: "";
  width: 100%;
  height: var(--RS-space-2);
  bottom: var(--RS-space-2-n);
  border-radius: var(--RS-space-8);
  transform-origin: 100%;
  transition: transform var(--RS-anim-duration-lg) var(--RS-default-transition);
  background: var(--RS-black-color);
  position: absolute;
  left: 0;
  transform: scaleX(0);
}

.RS-link:hover:before {
  transform-origin: 0;
  transform: scaleX(1);
}

@media (min-width: 860px) {
  :is(.RS-link--active:before, .RS-link--active:hover:before) {
    transform-origin: 0;
    transform: scaleX(.5);
  }
}

.RS-no-wrap {
  white-space: nowrap;
}

.RS-br-sm {
  display: initial;
}

@media (min-width: 860px) {
  .RS-br-sm {
    display: none;
  }
}

@media (min-width: 480px) {
  .RS-br-md {
    display: none;
  }
}

@media (min-width: 1024px) {
  .RS-br-md {
    display: initial;
  }
}

.RS-br-lg {
  display: none;
}

@media (min-width: 1024px) {
  .RS-br-lg {
    display: initial;
  }
}

.RS-inline-icon {
  min-width: calc(var(--RS-space-12)  + var(--RS-space-8));
  margin: 0 var(--RS-space-2);
  margin-bottom: var(--RS-space-2-n);
  font-size: 80%;
  line-height: 80%;
  display: inline-block;
}

.RS-inline-icon svg path {
  fill: var(--RS-brand-color);
}

.RS-inline-icon--grey svg path {
  fill: var(--RS-dark-grey-color-wcag);
}

.RS-inline-icon--graphite svg path {
  fill: var(--RS-graphite-color);
}

.RS-inline-icon--black svg path {
  fill: var(--RS-black-color);
}

.RS-inline-icon--multiline {
  margin-top: var(--RS-space-6);
  margin-right: var(--RS-space-6);
  min-width: calc(var(--RS-space-12)  - var(--RS-space-2));
}

@media (min-width: 1024px) {
  .RS-inline-icon--multiline {
    margin-top: var(--RS-space-4);
    margin-right: var(--RS-space-12);
    min-width: calc(var(--RS-space-12)  + var(--RS-space-2));
  }
}

.RS-inline-icon--tile-header {
  min-width: calc(var(--RS-space-12)  + var(--RS-space-2));
  transform: translateY(var(--RS-space-2-n));
}

@media (min-width: 1024px) {
  .RS-inline-icon--tile-header {
    min-width: calc(var(--RS-space-12)  + var(--RS-space-8));
    margin-right: var(--RS-space-4);
    transform: translateY(var(--RS-space-2-n));
  }
}

.RS-inline-icon--tile-header-secondary {
  min-width: calc(var(--RS-space-12)  + var(--RS-space-2));
  margin-right: var(--RS-space-4);
  transform: translateY(var(--RS-space-2-n));
}

@media (min-width: 1024px) {
  .RS-inline-icon--tile-header-secondary {
    min-width: calc(var(--RS-space-12)  + var(--RS-space-6));
    margin-right: var(--RS-space-8);
    transform: translateY(var(--RS-space-2-n));
  }
}

.RS-inline-icon--hero-marquee {
  min-width: calc(var(--RS-space-12)  + var(--RS-space-2));
  transform: translateY(var(--RS-space-2));
  position: absolute;
}

@media (min-width: 1024px) {
  .RS-inline-icon--hero-marquee {
    min-width: calc(var(--RS-space-12)  + var(--RS-space-6));
    margin-right: var(--RS-space-4);
    transform: translateX(var(--RS-space-4)) translateY(var(--RS-space-2));
  }
}

.RS-font-weight-bold {
  font-weight: var(--RS-font-weight-variable-bold);
}

.RS-font-weight-regular {
  font-weight: var(--RS-font-weight-variable-regular);
}

.RS-disclaimer {
  font-size: var(--RS-font-size-disclaimer);
  font-weight: var(--RS-font-weight-variable-light);
  color: var(--RS-dark-grey-color);
}

@media (min-width: 1024px) {
  .RS-disclaimer {
    font-size: var(--RS-font-size-disclaimer-desktop);
  }
}

.RS-font-color-brand {
  color: var(--RS-brand-color);
}

.RS-academic-title {
  font-family: PlayfairDisplay-Italic-Variable, serif;
}

.RS-font-crossed-out {
  position: relative;
}

.RS-font-crossed-out:after {
  content: "";
  width: 110%;
  height: 5%;
  background-color: var(--RS-black-color);
  border-radius: var(--RS-space-16);
  display: inline-block;
  position: absolute;
  top: 60%;
  left: -5%;
}

.RS-font-family-main {
  font-family: PublicSans-Variable, sans-serif;
}

html {
  font-size: 100%;
}

body {
  font-family: PublicSans-Variable, system-ui, sans-serif;
  font-size: var(--RS-font-size-p-desktop);
  font-weight: var(--RS-font-weight-variable-regular);
  color: var(--RS-black-color);
  letter-spacing: -.01em;
  background-color: var(--RS-white-color);
  line-height: 1;
  transition: none;
  overflow-x: hidden;
  overflow-y: auto;
}

body > main {
  width: 100%;
  min-height: 90vh;
  overflow: hidden;
}

a {
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

@media screen and (min--moz-device-pixel-ratio: 0) {
  :root {
    --RS-rotate-firefox-fix: .03deg;
  }

  svg, path {
    outline: 1px solid #fff0;
  }

  html {
    scrollbar-width: thin;
  }

  ::-moz-selection {
    color: var(--RS-light-grey-color);
    background: var(--RS-black-color);
  }
}

::selection {
  color: var(--RS-light-grey-color);
  background: var(--RS-black-color);
}

body::-webkit-scrollbar {
  width: var(--RS-space-6);
  height: var(--RS-space-4);
  background-color: var(--RS-white-color);
}

body::-webkit-scrollbar-thumb {
  background: var(--RS-black-color);
  border-radius: 4px;
}

[role="button"] {
  cursor: pointer;
}

svg path, svg g {
  transform-box: fill-box;
  transform-origin: center;
}

img, video {
  width: 100%;
  height: auto;
  outline: 1px solid #0000;
}

picture, img {
  border-radius: inherit;
  -webkit-user-select: none;
  user-select: none;
}

ul {
  list-style: none;
}

.RS-photo--square img {
  aspect-ratio: 1 / 1;
}

.RS-photo--vertical img {
  aspect-ratio: .665 / 1;
}

.RS-photo--horizontal img {
  aspect-ratio: 1.5 / 1;
}

.RS-section {
  width: 100%;
  padding: var(--RS-space-64) var(--RS-space-24) var(--RS-space-96);
  position: relative;
}

@media (min-width: 860px) {
  .RS-section {
    padding: var(--RS-space-64) var(--RS-space-24) var(--RS-space-128);
  }
}

@media (min-width: 1024px) {
  .RS-section {
    padding: var(--RS-space-96) var(--RS-space-32) var(--RS-space-128);
  }
}

.RS-section--inverted {
  background-color: var(--RS-black-color);
  color: var(--RS-white-color);
}

.RS-section-newsletter--standalone {
  padding: 12vh var(--RS-space-24) 4vh;
}

@media (min-width: 1024px) {
  .RS-section-newsletter--standalone {
    padding: 12vh var(--RS-space-32) 12vh;
  }
}

.RS-section--popup {
  height: 100vh;
  width: 100vw;
  z-index: 999;
  -webkit-backdrop-filter: blur(var(--RS-space-4));
  backdrop-filter: blur(var(--RS-space-4));
  cursor: pointer;
  opacity: 0;
  transition-timing-function: var(--RS-default-transition);
  transition-duration: var(--RS-anim-duration-md);
  padding: var(--RS-space-32);
  background: #0f111380;
  justify-content: center;
  align-items: center;
  transition-property: opacity;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 1px 51px 101px -21px #32325d0d, 1px 31px 101px -31px #00000014;
}

@media (min-width: 860px) {
  .RS-section--popup {
    padding: var(--RS-space-32);
  }
}

@media (min-width: 1024px) {
  .RS-section--popup {
    padding: var(--RS-space-32);
  }
}

@media (min-width: 1280px) {
  .RS-section--popup {
    padding: var(--RS-space-32);
  }
}

.RS-section--popup.RS-section--popup-displayed {
  display: flex;
}

.RS-section--popup.RS-section--popup-visible {
  opacity: 1;
}

.RS-section--popup .RS-container {
  max-width: var(--RS-col-6);
  border-radius: var(--RS-space-24);
  border: var(--RS-space-2) solid var(--RS-accent-color-primary);
  border-right-color: var(--RS-accent-color-secondary);
  border-bottom-color: var(--RS-accent-color-secondary);
  padding: var(--RS-space-32);
  cursor: default;
  min-height: 80%;
  height: 80%;
  background: #fff;
  overflow: hidden;
}

.RS-section--popup .RS-list__tile {
  gap: var(--RS-space-16);
}

.RS-section--popup .RS-list__tile:first-of-type {
  display: none;
}

@media (min-width: 860px) {
  .RS-section--popup .RS-list__tile:first-of-type {
    display: flex;
  }
}

.RS-section--popup__close {
  top: var(--RS-space-32);
  right: var(--RS-space-32);
  cursor: pointer;
  position: absolute;
}

.RS-container {
  z-index: 1;
  width: 100%;
  max-width: var(--RS-col-8);
  min-height: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  display: flex;
  position: relative;
}

.RS-container.RS-container--narrow {
  max-width: var(--RS-col-4);
}

.RS-content {
  flex-shrink: 0;
}

.RS-content--no-overflow {
  overflow: hidden;
}

.RS-content__figure {
  width: 100%;
  height: 100%;
}

.RS-section-about-experts .RS-content {
  margin-top: var(--RS-space-48-n);
  max-width: calc(var(--RS-space-192)  + var(--RS-space-64));
}

@media (min-width: 860px) {
  .RS-section-about-experts .RS-content {
    max-width: calc(var(--RS-space-256)  + var(--RS-space-4)  + var(--RS-space-1));
    margin-top: var(--RS-space-32-n);
    margin-bottom: var(--RS-space-64-n);
  }
}

.RS-content__figure--about-experts {
  width: 80%;
  margin: var(--RS-space-16) auto 0 auto;
}

@media (min-width: 860px) {
  .RS-content__figure--about-experts {
    width: 100%;
    margin: unset;
  }
}

.RS-section-about-experts .RS-content .RS-header__description {
  margin-top: var(--RS-space-48-n);
  margin-left: 0;
  margin-right: 0;
}

@media (min-width: 860px) {
  .RS-section-about-experts .RS-content .RS-header__description {
    transform: translateX(var(--RS-space-48));
    margin-top: var(--RS-space-64-n);
    width: calc(100% + var(--RS-space-24));
  }
}

@media (min-width: 860px) {
  .RS-section-about-experts .RS-article:last-of-type .RS-content .RS-header__description {
    transform: translateX(var(--RS-space-64-n));
  }
}

.RS-section-about-experts .RS-article:last-of-type .RS-content .RS-header__description--highlighted > svg {
  fill: var(--RS-accent-color-secondary);
}

.RS-section-about-project .RS-list--tiles .RS-header__description--secondary {
  text-align: center;
}

.RS-section-about-user .RS-content {
  flex-direction: column;
  display: flex;
}

@media (min-width: 860px) {
  .RS-section-about-user .RS-content {
    flex-direction: row;
  }
}

@media (min-width: 860px) {
  .RS-section-about-user .RS-content > div {
    width: 40%;
  }
}

@media (min-width: 860px) {
  .RS-section-about-user .RS-content > ul {
    width: 60%;
  }
}

.RS-section-about-process .RS-content {
  flex-direction: column;
  display: flex;
}

@media (min-width: 860px) {
  .RS-section-about-process .RS-content {
    flex-direction: row;
  }
}

.RS-section-about-process .RS-header + .RS-content {
  margin-top: var(--RS-space-24);
}

.RS-background-pointer-arrow > svg {
  width: calc(var(--RS-space-32)  + var(--RS-space-6));
  position: absolute;
  top: 0%;
}

.RS-background-pointer-arrow > svg path {
  fill: var(--RS-light-grey-color);
}

.RS-background-pointer-arrow:first-of-type > svg {
  left: calc(var(--RS-space-64-n)  + var(--RS-space-8-n));
}

.RS-background-pointer-arrow:last-of-type > svg {
  right: calc(var(--RS-space-64-n)  + var(--RS-space-8-n));
}

.RS-section-about-product .RS-background-pointer-arrow:first-of-type > svg {
  right: calc(var(--RS-space-64-n)  + var(--RS-space-8-n));
  left: unset;
  transform: rotateY(180deg);
}

@media (min-width: 860px) {
  .RS-section-about-product .RS-background-pointer-arrow:first-of-type > svg {
    right: unset;
    left: calc(var(--RS-space-64-n)  + var(--RS-space-8-n));
    transform: unset;
  }
}

.RS-section-about-user {
  margin-bottom: var(--RS-space-64);
}

@media (min-width: 860px) {
  .RS-section-about-user {
    margin-bottom: 0;
  }
}

.RS-section-about-process .RS-header__description {
  text-align: center;
}

@media (min-width: 860px) {
  .RS-section-about-process .RS-header__description {
    text-align: left;
  }
}

.RS-hero-content__ola {
  top: calc(0px + var(--RS-space-128)  + var(--RS-space-48));
  right: calc(0px - var(--RS-space-256)  - var(--RS-space-16));
  z-index: -1;
  position: absolute;
}

@media (min-width: 860px) {
  .RS-hero-content__ola {
    top: calc(0px - var(--RS-space-128)  + var(--RS-space-4));
    right: -20%;
  }
}

.RS-animation {
  opacity: .01;
  transition-timing-function: var(--RS-default-transition);
  transition-duration: var(--RS-anim-duration-lg), var(--RS-anim-duration-xlg);
  transform: translate3d(0, 0, 0) rotate(var(--RS-rotate-firefox-fix));
  transform-style: preserve-3d;
  transition-property: opacity, transform;
}

.RS-animation__slow {
  transition-duration: var(--RS-anim-duration-lg), var(--RS-anim-duration-2xlg);
}

.RS-animation.RS-animation--enter {
  opacity: 1;
  transform: translate3d(0, 0, 0) scaleY(1) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__text-to-top {
  transform: translate3d(0, 32px, 0) scaleY(.96) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__text-to-bottom {
  transform: translate3d(0, -32px, 0) scaleY(.96) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__text-to-left {
  transform: translate3d(13px, 0, 0) scaleY(.96) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__text-to-right {
  transform: translate3d(-8px, 0, 0) scaleY(.96) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__image-to-right {
  transform: translate3d(var(--RS-space-24), 0, 0) scaleY(.995) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__image-to-left {
  transform: translate3d(var(--RS-space-24-n), 0, 0) scaleY(.995) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__image-to-top {
  transform: translate3d(0, var(--RS-space-16), 0) scaleY(.995) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__image-to-bottom {
  transform: translate3d(0, var(--RS-space-16-n), 0) scaleY(.995) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__opacity {
  opacity: .01;
  transform: unset;
  transform-style: unset;
}

.RS-animation.RS-animation__opacity.RS-animation--enter {
  transform: unset;
}

.RS-animation__background-decoration--hero-first {
  transform: translate3d(0, 0, 0) rotate(var(--RS-rotate-firefox-fix));
  opacity: 0;
  animation: 20s linear infinite animBgDecorHero1;
}

@keyframes animBgDecorHero1 {
  0%, 2.5%, 97.5%, 100% {
    opacity: 0;
  }

  10%, 90% {
    opacity: 1;
  }

  97.5% {
    transform: translateX(-80%)translateY(-70%)scale(.9)rotateZ(-60deg);
  }
}

.RS-animation__background-decoration--hero-second {
  transform: translate3d(0, 0, 0) rotate(var(--RS-rotate-firefox-fix));
  opacity: 0;
  animation: 18s linear infinite animBgDecorHero2;
}

@keyframes animBgDecorHero2 {
  0%, 2.5%, 97.5%, 100% {
    opacity: 0;
  }

  10%, 90% {
    opacity: 1;
  }

  97.5% {
    transform: translateX(80%)translateY(70%)scale(.9)rotateZ(60deg);
  }
}

.RS-animation__background-decoration--about-project {
  transform: translate3d(0, 0, 0) rotate(var(--RS-rotate-firefox-fix));
  opacity: 0;
  animation: 19s linear infinite animBgDecorAboutProject;
}

@keyframes animBgDecorAboutProject {
  0%, 2.5%, 97.5%, 100% {
    opacity: 0;
  }

  10%, 90% {
    opacity: 1;
  }

  97.5% {
    transform: translateX(-50%)translateY(-160%)scale(1.2)rotateZ(-90deg);
  }
}

.RS-animation__background-decoration--footer {
  transform: translate3d(0, 0, 0) rotate(var(--RS-rotate-firefox-fix));
  opacity: 0;
  animation: 20s linear infinite animBgDecorFooter;
}

@keyframes animBgDecorFooter {
  0%, 2.5%, 97.5%, 100% {
    opacity: 0;
  }

  10%, 90% {
    opacity: 1;
  }

  97.5% {
    transform: translateX(80%)translateY(-70%)scale(.9)rotateZ(-67deg);
  }
}

.RS-looped-animation {
  backface-visibility: hidden;
  will-change: transform;
  animation-play-state: paused !important;
}

.RS-looped-animation--running {
  animation-play-state: running !important;
}

.RS-top-bar {
  z-index: 666;
  width: 100%;
  padding: var(--RS-space-24);
  transition: transform var(--RS-anim-duration-lg) var(--RS-default-transition);
  backface-visibility: hidden;
  position: fixed;
  inset: 0 0 auto;
  transform: translate3d(0, 0, 0);
}

@media (min-width: 860px) {
  .RS-top-bar {
    padding: var(--RS-space-24) var(--RS-space-32);
  }
}

.RS-top-bar:before {
  content: "";
  width: inherit;
  height: 100%;
  z-index: -1;
  background: var(--RS-white-color);
  opacity: 1;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-mask-image: linear-gradient(#000, #0000 100%);
  mask-image: linear-gradient(#000, #0000 100%);
}

.RS-top-bar--hidden {
  transform: translate3d(0, -100%, 0);
}

.RS-top-bar__container {
  max-width: var(--RS-col-8);
  height: 32px;
  flex-flow: row;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

.RS-top-bar__brand {
  z-index: 1;
}

.RS-top-bar__logo {
  width: calc(var(--RS-space-128)  - var(--RS-space-8));
  height: calc(var(--RS-space-32)  - var(--RS-space-2));
  margin: 0 var(--RS-space-8) 0 0;
}

@media (min-width: 860px) {
  .RS-top-bar__logo {
    width: var(--RS-space-128);
    height: calc(var(--RS-space-32)  + 0px);
  }
}

.RS-top-bar__nav {
  height: 100vh;
  width: 100vw;
  background: var(--RS-white-color);
  top: var(--RS-space-24-n);
  left: var(--RS-space-24-n);
  justify-content: center;
  align-items: center;
  gap: var(--RS-space-48);
  transition: transform var(--RS-anim-duration-lg) var(--RS-default-transition);
  transform: translateX(calc(-100% - var(--RS-space-48)));
  font-size: var(--RS-font-size-mobile-nav);
  flex-direction: column;
  display: none;
  position: fixed;
}

@media (min-width: 860px) {
  .RS-top-bar__nav {
    height: inherit;
    justify-content: flex-end;
    gap: calc(var(--RS-space-32)  + var(--RS-space-4));
    width: unset;
    font-size: inherit;
    background: unset;
    top: unset;
    left: unset;
    font-size: var(--RS-font-size-top-nav);
    flex-direction: row;
    margin-left: auto;
    display: flex;
    position: relative;
    transform: none;
  }
}

.RS-top-bar__nav:after {
  content: "";
  right: var(--RS-space-48-n);
  width: inherit;
  height: 100%;
  width: var(--RS-space-48);
  background: inherit;
  border-radius: 0 50% 50% 0;
  display: block;
  position: absolute;
  top: 0;
}

.RS-top-bar__nav--displayed {
  display: flex;
}

.RS-top-bar__nav--opened {
  transform: translateX(0%);
}

.RS-top-bar__nav > .RS-link {
  padding-bottom: var(--RS-space-4);
  opacity: 0;
  transform: translateY(var(--RS-space-32-n));
  transition: transform var(--RS-anim-duration-md) var(--RS-default-transition), opacity var(--RS-anim-duration-md) var(--RS-default-transition);
  letter-spacing: .25px;
}

@media (min-width: 860px) {
  .RS-top-bar__nav > .RS-link {
    opacity: 1;
    font-weight: var(--RS-font-weight-variable-bold);
    letter-spacing: 0;
    transform: none;
  }
}

.RS-top-bar__nav > .RS-link--visible {
  opacity: 1;
  transform: none;
}

.RS-top-bar__nav > .RS-link--mobile {
  display: initial;
}

@media (min-width: 860px) {
  .RS-top-bar__nav > .RS-link--mobile {
    display: none;
  }
}

.RS-top-bar__nav > .RS-link:first-of-type {
  transition-delay: calc(var(--RS-anim-delay) * 1);
}

@media (min-width: 860px) {
  .RS-top-bar__nav > .RS-link:first-of-type {
    transition-delay: unset;
  }
}

.RS-top-bar__nav > .RS-link:nth-of-type(2) {
  transition-delay: calc(var(--RS-anim-delay) * 2);
}

@media (min-width: 860px) {
  .RS-top-bar__nav > .RS-link:nth-of-type(2) {
    transition-delay: unset;
  }
}

.RS-top-bar__nav > .RS-link:nth-of-type(3) {
  transition-delay: calc(var(--RS-anim-delay) * 3);
}

@media (min-width: 860px) {
  .RS-top-bar__nav > .RS-link:nth-of-type(3) {
    transition-delay: unset;
  }
}

.RS-top-bar__nav > .RS-link:nth-of-type(4) {
  transition-delay: calc(var(--RS-anim-delay) * 4);
}

@media (min-width: 860px) {
  .RS-top-bar__nav > .RS-link:nth-of-type(4) {
    transition-delay: unset;
  }
}

.RS-top-bar__nav > .RS-link:nth-of-type(5) {
  transition-delay: calc(var(--RS-anim-delay) * 5);
}

@media (min-width: 860px) {
  .RS-top-bar__nav > .RS-link:nth-of-type(5) {
    transition-delay: unset;
  }
}

.RS-top-bar__nav > .RS-link:nth-of-type(6) {
  transition-delay: calc(var(--RS-anim-delay) * 6);
}

@media (min-width: 860px) {
  .RS-top-bar__nav > .RS-link:nth-of-type(6) {
    transition-delay: unset;
  }
}

.RS-top-bar__nav > .RS-link:nth-of-type(7) {
  transition-delay: calc(var(--RS-anim-delay) * 7);
}

@media (min-width: 860px) {
  .RS-top-bar__nav > .RS-link:nth-of-type(7) {
    transition-delay: unset;
  }
}

.RS-link__badge {
  font-size: var(--RS-font-size-disclaimer);
  letter-spacing: 0;
  font-weight: var(--RS-font-weight-variable-regular);
  padding: var(--RS-space-4) var(--RS-space-12);
  border-radius: var(--RS-space-16);
  background: var(--RS-black-color);
  color: var(--RS-white-color);
  margin-left: var(--RS-space-4);
  opacity: .21;
}

.RS-top-bar__mobile-nav-icon {
  z-index: 1;
  -webkit-tap-highlight-color: transparent;
  flex: 1;
  margin-left: auto;
  display: block;
}

@media (min-width: 860px) {
  .RS-top-bar__mobile-nav-icon {
    display: none;
  }
}

.RS-top-bar__mobile-nav-icon span {
  width: calc(var(--RS-space-32)  - var(--RS-space-2));
  height: calc(var(--RS-space-2)  + var(--RS-space-1));
  background-color: var(--RS-brand-color);
  margin: var(--RS-space-4) 0 var(--RS-space-4) auto;
  transition: transform var(--RS-anim-duration-md) var(--RS-default-transition);
  transform-origin: center;
  border-radius: var(--RS-space-12);
  display: block;
}

.RS-top-bar__mobile-nav-icon--active span:first-of-type {
  transform: rotateZ(45deg) translateY(calc(var(--RS-space-4)  + var(--RS-space-1)));
}

.RS-top-bar__mobile-nav-icon--active span:nth-of-type(2) {
  transform: rotateZ(-45deg) translateY(calc(var(--RS-space-4-n)  - var(--RS-space-1)));
}

.RS-top-bar__nav, .RS-top-bar__dummy {
  flex: 1;
}

.RS-top-bar__floating-pill-inner {
  background-color: var(--RS-graphite-color);
  padding: calc(var(--RS-space-6)  + var(--RS-space-1)) var(--RS-space-16);
  color: var(--RS-white-color);
  font-size: var(--RS-font-size-disclaimer);
  width: -moz-fit-content;
  width: fit-content;
  margin: calc(var(--RS-space-12)  - var(--RS-space-6)) auto 0 auto;
  border-radius: var(--RS-space-16);
  gap: var(--RS-space-4);
  text-align: center;
  transition: transform var(--RS-anim-duration-lg) var(--RS-default-transition), opacity var(--RS-anim-duration-xs) var(--RS-default-transition);
  transform: translate3d(0, 0, 0);
}

@media (min-width: 376px) {
  .RS-top-bar__floating-pill-inner {
    margin: calc(var(--RS-space-12)  - var(--RS-space-2)) auto 0 auto;
  }
}

@media (min-width: 860px) {
  .RS-top-bar__floating-pill-inner {
    margin: var(--RS-space-12) auto 0 auto;
  }
}

@media (min-width: 1024px) {
  .RS-top-bar__floating-pill-inner {
    font-size: var(--RS-font-size-disclaimer-desktop);
    padding: var(--RS-space-8) var(--RS-space-24);
  }
}

.RS-top-bar__floating-pill-inner--hidden {
  opacity: 0;
  transform: translate3d(0, -100%, 0);
}

.RS-top-bar__floating-pill .RS-link:before {
  background-color: var(--RS-accent-color-secondary);
  bottom: var(--RS-space-1);
  height: var(--RS-space-1);
  transform: scale(1);
}

.RS-button {
  -webkit-appearance: none;
  appearance: none;
  color: var(--RS-white-color);
  cursor: pointer;
  font: inherit;
  font-weight: var(--RS-font-weight-variable-regular);
  font-size: var(--RS-font-size-button);
  padding: calc(var(--RS-space-12)  + var(--RS-space-2)) calc(var(--RS-space-32)  + var(--RS-space-2)) var(--RS-space-12) calc(var(--RS-space-32)  + var(--RS-space-2));
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  background: none;
  border: 0;
  line-height: 1;
  display: inline-flex;
  position: relative;
}

@media (min-width: 1024px) {
  .RS-button {
    font-size: var(--RS-font-size-button-desktop);
  }
}

.RS-button:focus {
  outline: unset;
}

.RS-button__content {
  transition: transform var(--RS-anim-duration-md) var(--RS-bounce-transition);
  display: block;
  position: relative;
}

.RS-button__shape {
  fill: #004940;
  inline-size: 100%;
  block-size: 100%;
  transition: transform var(--RS-anim-duration-md) var(--RS-bounce-transition);
  filter: url("#RS-button-svg-filter");
  position: absolute;
  inset: 0;
  overflow: visible;
}

.RS-button__path {
  transition: d var(--RS-anim-duration-md) var(--RS-bounce-transition);
  fill: var(--RS-cta-color);
  d: path("M0,-5 C30,10 70,10 100,-5 C100,30 100,70 100,100 C70,110 30,110 0,100 C0,70 0,30 0,0 z");
}

.RS-button--brand .RS-button__path {
  fill: var(--RS-brand-color);
}

.RS-button:focus .RS-button__path, .RS-button:hover .RS-button__path {
  d: path("M0,0 C0,-7.5 100,-7.5 100,0 C100,0 100,100 100,100 C100,107.5 0,107.5 0,100 C0,100 -0,0 0,0 z");
}

.RS-button:focus, .RS-button.RS-button--active, .RS-button:hover {
  filter: brightness(1.1);
}

.RS-button:focus .RS-button__shape, .RS-button:hover .RS-button__shape {
  transform: scaleX(.98);
}

.RS-button:focus .RS-button__content, .RS-button:hover .RS-button__content {
  transform: translateY(-2px);
}

.RS-button--inverted {
  color: var(--RS-black-color);
  background-color: var(--RS-light-grey-color);
}

.RS-button--inverted.RS-button--active, .RS-button--inverted:hover {
  color: var(--RS-black-color);
  background-color: var(--RS-white-color);
}

.RS-button--small {
  padding: var(--RS-space-12) var(--RS-space-16);
  min-width: unset;
}

.RS-button--ghost {
  background-color: unset;
  color: var(--RS-black-color);
  border-radius: var(--RS-space-32);
}

.RS-button--ghost.RS-button--active, .RS-button--ghost:hover {
  color: var(--RS-black-color);
}

.RS-button > svg.fa-arrow-right {
  margin: 0 0 0 var(--RS-space-16);
  transition: transform var(--RS-anim-duration-md) var(--RS-default-transition);
  font-size: clamp(1.25rem, 1.1994rem + .2532vw, 1.5rem);
  line-height: 1;
  transform: translate3d(0, 0, 0);
}

.RS-button.RS-button--nav {
  width: auto;
  max-width: none;
  background: unset;
  color: var(--RS-black-color);
  font-weight: var(--RS-font-weight-variable-regular);
  padding: 0 0 var(--RS-space-4) 0;
  font-size: var(--RS-font-size-mobile-nav);
}

@media (min-width: 860px) {
  .RS-button.RS-button--nav {
    color: var(--RS-white-color);
    padding: var(--RS-space-12) calc(var(--RS-space-24)  + var(--RS-space-2)) calc(var(--RS-space-12)  - var(--RS-space-2)) calc(var(--RS-space-24)  + var(--RS-space-2));
    opacity: 1;
    font-size: var(--RS-font-size-top-nav);
  }
}

.RS-button.RS-button--nav .RS-button__shape {
  display: none;
}

@media (min-width: 860px) {
  .RS-button.RS-button--nav .RS-button__shape {
    display: initial;
  }
}

@media (min-width: 860px) {
  :is(.RS-button.RS-button--nav.RS-button--active, .RS-button.RS-button--nav:hover) {
    color: var(--RS-white-color);
  }
}

.RS-button + .RS-disclaimer {
  margin: var(--RS-space-16) 0 0 0;
}

.RS-button__icon > svg {
  height: var(--RS-space-12);
}

.RS-button__icon > svg path {
  fill: var(--RS-black-color);
}

.RS-list {
  font-weight: var(--RS-font-weight-variable-bold);
  padding: var(--RS-space-32) 0 0 0;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.RS-list--tiles {
  width: 100%;
  justify-content: space-between;
  align-items: stretch;
  gap: var(--RS-space-32);
  padding: var(--RS-space-32) 0 0 0;
  flex-direction: column;
}

@media (min-width: 860px) {
  .RS-list--tiles {
    gap: var(--RS-space-64);
    padding: var(--RS-space-32) 0 0 0;
    flex-direction: row;
  }
}

.RS-list--tiles-about-user {
  padding: var(--RS-space-32) 0 0 0;
}

@media (min-width: 860px) {
  .RS-list--tiles-about-user {
    flex-direction: column;
    padding: 0;
  }
}

.RS-list--tiles-about-process {
  padding: var(--RS-space-16) 0 0 0;
  gap: var(--RS-space-32);
}

@media (min-width: 860px) {
  .RS-list--tiles-about-process {
    flex-direction: column;
    gap: 0;
    padding: 0;
  }
}

.RS-list--tiles-about-benefits {
  flex-direction: column;
  align-items: stretch;
  margin: 0;
}

@media (min-width: 860px) {
  .RS-list--tiles-about-benefits {
    margin: var(--RS-space-32) 0 0 0;
    flex-direction: row;
  }
}

.RS-list--tiles-about-product {
  gap: var(--RS-space-32);
  flex-direction: column;
  margin: 0;
  padding: 0;
}

@media (min-width: 860px) {
  .RS-list--tiles-about-product {
    flex-direction: row;
    margin: 0;
    padding: 0;
  }
}

.RS-list--tiles-about-product-alt {
  gap: var(--RS-space-32);
  margin: var(--RS-space-16) 0 0 0;
  padding: var(--RS-space-48) 0 0 0;
  flex-direction: column;
  align-items: stretch;
}

@media (min-width: 860px) {
  .RS-list--tiles-about-product-alt {
    margin: var(--RS-space-32) 0 0 0;
    padding: var(--RS-space-32) 0 0 0;
    flex-direction: row;
  }
}

.RS-list--tiles-about-products {
  gap: var(--RS-space-32);
  margin: var(--RS-space-32) 0 0 0;
  flex-direction: column;
  padding: 0;
}

@media (min-width: 860px) {
  .RS-list--tiles-about-products {
    margin: var(--RS-space-64) 0 0 0;
    flex-direction: row;
    padding: 0;
  }
}

.RS-list--tiles-about-report-content {
  gap: var(--RS-space-32);
  margin: var(--RS-space-16) 0 0 0;
  padding: var(--RS-space-24) 0 0 0;
  flex-direction: column;
  align-items: stretch;
}

@media (min-width: 860px) {
  .RS-list--tiles-about-report-content {
    margin: var(--RS-space-32) 0 0 0;
    padding: var(--RS-space-32) 0 0 0;
  }
}

.RS-list--tiles-inner {
  gap: var(--RS-space-16);
  flex-direction: column;
  margin: 0;
  padding: 0;
}

@media (min-width: 860px) {
  .RS-list--tiles-inner {
    gap: var(--RS-space-16);
    padding: 0;
  }
}

.RS-list__tile {
  padding: var(--RS-space-8) var(--RS-space-12) var(--RS-space-12) var(--RS-space-12);
  width: 100%;
  gap: var(--RS-space-16);
  border-radius: var(--RS-space-24);
  font-size: var(--RS-font-size-p);
  line-height: var(--RS-line-height-md);
  flex-direction: column;
  display: flex;
  position: relative;
}

@media (min-width: 1024px) {
  .RS-list__tile {
    font-size: var(--RS-font-size-p-desktop);
    padding: var(--RS-space-16) var(--RS-space-16) var(--RS-space-16) var(--RS-space-16);
  }
}

.RS-list__tile--about-user {
  border: 1px solid var(--RS-light-grey-color);
  border-radius: var(--RS-space-24);
  background-color: var(--RS-white-color);
  padding: var(--RS-space-24);
  gap: 0;
  position: relative;
  overflow: hidden;
}

@media (min-width: 1024px) {
  .RS-list__tile--about-user {
    padding: var(--RS-space-32) var(--RS-space-32);
  }
}

.RS-list__tile--about-user:before {
  content: "";
  width: calc(var(--RS-space-128)  + var(--RS-space-64));
  height: calc(var(--RS-space-128)  + var(--RS-space-64));
  top: calc(0px - (var(--RS-space-128)  + var(--RS-space-64)) / 2);
  right: calc(0px - (var(--RS-space-128)  + var(--RS-space-64)) / 2);
  border-radius: var(--RS-space-48);
  z-index: -1;
  display: block;
  position: absolute;
}

@media (min-width: 1024px) {
  .RS-list__tile--about-user:before {
    width: calc(var(--RS-space-128)  + var(--RS-space-64));
    height: calc(var(--RS-space-128)  + var(--RS-space-64));
    top: calc(0px - (var(--RS-space-128)  + var(--RS-space-64)) / 2);
    right: calc(0px - (var(--RS-space-128)  + var(--RS-space-64)) / 2);
  }
}

.RS-list__tile--about-user-first:before {
  background: var(--RS-accent-color-primary);
  transform: skewX(8deg)rotateZ(15deg);
}

.RS-list__tile--about-user-second:before {
  background: var(--RS-accent-color-secondary);
  transform: skewX(8deg)rotateZ(-15deg);
}

.RS-list__tile--about-user-third:before {
  background: var(--RS-accent-color-primary);
  transform: skewX(-4deg)rotateZ(15deg);
}

.RS-list__tile--about-user-fourth:before {
  background: var(--RS-accent-color-secondary);
  transform: skewX(4deg)rotateZ(-15deg);
}

.RS-list__tile--about-user-fifth:before {
  background: var(--RS-accent-color-primary);
  transform: skewX(-6deg)rotateZ(5deg);
}

.RS-list__tile--about-user-sixth:before {
  background: var(--RS-accent-color-secondary);
  transform: skewX(6deg)rotateZ(-5deg);
}

.RS-list__tile--about-process {
  gap: var(--RS-space-24);
  flex-direction: column;
  margin: 0;
  padding: 0;
  position: relative;
}

@media (min-width: 1024px) {
  .RS-list__tile--about-process {
    gap: var(--RS-space-48);
    padding: var(--RS-space-24) 0;
    flex-direction: row;
    margin: 0;
  }
}

.RS-list__tile--about-benefits {
  border-radius: var(--RS-space-24);
  background-color: var(--RS-accent-color-secondary);
  padding: var(--RS-space-24);
  gap: 0;
}

@media (min-width: 1024px) {
  .RS-list__tile--about-benefits {
    padding: var(--RS-space-32) var(--RS-space-32);
  }
}

.RS-list__tile--about-product {
  align-items: flex-start;
  padding: 0;
}

@media (min-width: 1024px) {
  .RS-list__tile--about-product {
    gap: var(--RS-space-32);
  }
}

.RS-list__tile--hero-about-product:first-of-type {
  align-items: center;
}

.RS-list__tile--hero-about-product {
  align-items: center;
}

@media (min-width: 1024px) {
  .RS-list__tile--hero-about-product {
    gap: var(--RS-space-32);
  }
}

.RS-list__tile--about-product-popup {
  justify-content: center;
}

.RS-list__tile--about-product-alt {
  border: 1px solid var(--RS-light-grey-color);
  border-radius: var(--RS-space-24);
  background-color: var(--RS-white-color);
  padding: var(--RS-space-24);
  gap: 0;
}

@media (min-width: 1024px) {
  .RS-list__tile--about-product-alt {
    padding: var(--RS-space-32) var(--RS-space-32);
  }
}

.RS-list__tile--about-products {
  gap: var(--RS-space-24);
  justify-content: space-between;
  align-items: flex-start;
  padding: 0;
}

@media (min-width: 1024px) {
  .RS-list__tile--about-products {
    gap: var(--RS-space-32);
  }
}

.RS-list__tile--about-products:first-of-type .RS-header__description--primary {
  margin-top: auto;
}

.RS-list__tile--about-product-alt:nth-of-type(2), .RS-list__tile--about-product-alt:nth-of-type(4) {
  border: 1px solid var(--RS-accent-color-primary);
  background-color: var(--RS-accent-color-primary);
}

.RS-list__tile--about-product-alt .RS-header__description--tile {
  align-items: flex-start;
}

.RS-list__tile--about-product-alt .RS-header__description--tile svg {
  margin-bottom: unset;
  transform: translateY(67%);
}

.RS-list__tile--about-report-content {
  border: 1px solid var(--RS-light-grey-color);
  border-radius: var(--RS-space-24);
  background-color: var(--RS-white-color);
  gap: var(--RS-space-24);
  padding: var(--RS-space-24);
  flex-direction: column;
}

@media (min-width: 860px) {
  .RS-list__tile--about-report-content {
    gap: var(--RS-space-48);
    flex-direction: column;
  }
}

@media (min-width: 1024px) {
  .RS-list__tile--about-report-content {
    padding: var(--RS-space-32) var(--RS-space-32);
  }
}

.RS-list--tiles-about-report-content:nth-of-type(2n) .RS-list__tile--about-report-content {
  flex-direction: column;
}

@media (min-width: 860px) {
  .RS-list--tiles-about-report-content:nth-of-type(2n) .RS-list__tile--about-report-content {
    flex-direction: column;
  }
}

.RS-list__tile--about-report-content > div {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

@media (min-width: 860px) {
  .RS-list__tile--about-report-content > div {
    width: 100%;
  }
}

.RS-list__tile--about-report-content img {
  border: var(--RS-space-1) solid var(--RS-light-grey-color);
  box-sizing: content-box;
  width: 100%;
}

.RS-list__tile--about-facts {
  border-radius: var(--RS-space-24);
  padding: var(--RS-space-24);
  gap: 0;
  gap: var(--RS-space-16);
  height: 100%;
  overflow: hidden;
}

@media (min-width: 1024px) {
  .RS-list__tile--about-facts {
    padding: var(--RS-space-32) var(--RS-space-32);
  }
}

.RS-list__tile--about-facts--first {
  background-color: var(--RS-accent-color-secondary);
}

.RS-list__tile--about-facts--third {
  background-color: var(--RS-accent-color-primary);
}

.RS-list__tile--about-facts--second {
  border: 1px solid var(--RS-light-grey-color);
}

.RS-list__tile--about-facts > p:first-of-type {
  padding: var(--RS-space-8) var(--RS-space-16);
  border-radius: var(--RS-space-6);
  align-self: flex-start;
}

.RS-list__tile--about-facts--first > p:first-of-type {
  background: var(--RS-white-color);
}

.RS-list__tile--about-facts--second > p:first-of-type {
  background: var(--RS-accent-color-primary);
}

.RS-list__tile--about-facts--third > p:first-of-type {
  background: var(--RS-accent-color-secondary);
  background: var(--RS-white-color);
}

.RS-list__tile--about-facts span svg {
  height: calc(var(--RS-space-64)  - var(--RS-space-8));
  position: absolute;
}

.RS-list__tile--about-facts--first svg {
  bottom: var(--RS-space-6-n);
  right: var(--RS-space-12);
  transform: rotateZ(0);
}

.RS-list__tile--about-facts--second svg {
  bottom: calc(10% - var(--RS-space-6-n));
  right: var(--RS-space-6);
  transform: rotateZ(-90deg);
}

@media (min-width: 1024px) {
  .RS-list__tile--about-facts--second svg {
    bottom: calc(75% - var(--RS-space-6-n));
  }
}

.RS-list__tile--about-facts--third svg {
  fill: var(--RS-white-color);
  top: var(--RS-space-6-n);
  right: var(--RS-space-12);
  transform: rotateZ(180deg);
}

.RS-list__tile--about-facts--first svg path {
  fill: var(--RS-white-color);
}

.RS-list__tile--about-facts--second svg path {
  fill: var(--RS-light-grey-color);
  opacity: .5;
}

.RS-list__tile--about-facts--third svg path {
  fill: var(--RS-white-color);
}

.RS-list__tile--about-process > div {
  padding: var(--RS-space-24);
  position: relative;
}

.RS-list__tile--about-process > div:first-of-type {
  padding: var(--RS-space-32);
  min-width: var(--RS-space-96);
  color: var(--RS-brand-color);
  justify-content: center;
  align-items: center;
  display: flex;
}

.RS-list__tile--about-process > div:first-of-type:before {
  content: "";
  height: calc(100% + var(--RS-space-48));
  border: 1px solid var(--RS-light-grey-color);
  z-index: -2;
  position: absolute;
  left: 50%;
}

.RS-list__tile--about-process:first-of-type > div:first-of-type:before {
  -webkit-mask-image: linear-gradient(#0000 0%, #000 20% 100%);
  mask-image: linear-gradient(#0000 0%, #000 20% 100%);
}

.RS-list__tile--about-process:last-of-type > div:first-of-type:before {
  -webkit-mask-image: linear-gradient(#000 0% 80%, #0000 100%);
  mask-image: linear-gradient(#000 0% 80%, #0000 100%);
}

.RS-list__tile--about-process > div:first-of-type:after {
  content: "";
  width: var(--RS-space-96);
  height: var(--RS-space-96);
  border-radius: var(--RS-space-32);
  background: var(--RS-accent-color-primary);
  z-index: -1;
  display: block;
  position: absolute;
  transform: skewX(8deg)rotateZ(15deg);
}

.RS-list__tile--about-process:nth-of-type(3) > div:first-of-type:after {
  background: var(--RS-accent-color-secondary);
}

.RS-list__tile--about-process:nth-of-type(2) > div:first-of-type:after {
  background: var(--RS-graphite-color);
  transform: skewX(-8deg)rotateZ(-15deg);
}

.RS-list__tile--about-process > div:first-of-type {
  color: var(--RS-black-color);
}

.RS-list__tile--about-process:nth-of-type(2) > div:first-of-type {
  color: var(--RS-white-color);
}

.RS-list__tile--about-process > div:first-of-type > picture {
  opacity: 1;
  position: absolute;
}

.RS-list__tile--about-process > div:first-of-type > p > span {
  display: flex;
  transform: translateY(-10%);
}

.RS-list__tile--about-process > div:first-of-type > svg {
  height: calc(var(--RS-space-128)  + var(--RS-space-64));
  z-index: -1;
  display: none;
  position: absolute;
}

.RS-list__tile--about-process:nth-of-type(2) > div:first-of-type > svg {
  transform: rotateZ(180deg);
}

.RS-list__tile--about-process:nth-of-type(3) > div:first-of-type > svg {
  transform: rotateZ(0);
}

.RS-list__tile--about-process .RS-header__description--tile {
  justify-content: center;
}

@media (min-width: 860px) {
  .RS-list__tile--about-process .RS-header__description--tile {
    justify-content: flex-start;
  }
}

.RS-list__tile > svg {
  fill: var(--RS-accent-color-primary);
  inline-size: 100%;
  block-size: 100%;
  width: calc(100% + var(--RS-space-12) * 2);
  height: calc(100% + var(--RS-space-8) * 2);
  inset: 0;
  top: calc(var(--RS-space-8) * -1);
  left: calc(var(--RS-space-12) * -1);
  transition: transform var(--RS-anim-duration-md) var(--RS-bounce-transition);
  filter: url("#RS-highlight-svg-filter");
  z-index: -1;
  position: absolute;
  overflow: visible;
}

.RS-list__tile:nth-of-type(2n) > svg {
  fill: var(--RS-accent-color-secondary);
}

@media (min-width: 860px) {
  .RS-list--tiles-about-benefits:nth-of-type(2) .RS-list__tile:nth-of-type(2n) > svg {
    fill: var(--RS-accent-color-primary);
  }
}

@media (min-width: 860px) {
  .RS-list--tiles-about-benefits:nth-of-type(2) .RS-list__tile:nth-of-type(odd) > svg {
    fill: var(--RS-accent-color-secondary);
  }
}

.RS-list__tile-header {
  gap: var(--RS-space-16);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (min-width: 860px) {
  .RS-list__tile-header {
    flex-direction: row;
    justify-content: flex-start;
  }
}

.RS-list__tile-header + p {
  text-align: center;
}

@media (min-width: 860px) {
  .RS-list__tile-header + p {
    text-align: left;
  }
}

.RS-list__tile-icon-wrapper {
  position: relative;
}

.RS-list__tile-icon-wrapper {
  width: calc(var(--RS-space-64)  + var(--RS-space-16));
}

@media (min-width: 860px) {
  .RS-list__tile-icon-wrapper {
    width: auto;
  }
}

.RS-list__tile-icon > svg {
  width: calc(var(--RS-space-48)  - var(--RS-space-12));
  height: calc(var(--RS-space-48)  - var(--RS-space-12));
  fill: var(--RS-brand-color);
}

@media (min-width: 860px) {
  .RS-list__tile-icon > svg {
    width: calc(var(--RS-space-48)  - var(--RS-space-6));
    height: calc(var(--RS-space-48)  - var(--RS-space-6));
  }
}

.RS-list__tile-icon {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.RS-tile-product-bg {
  width: calc(var(--RS-space-256)  + var(--RS-space-64));
  height: calc(var(--RS-space-256)  + var(--RS-space-64));
  border-radius: var(--RS-space-64);
  position: absolute;
}

.RS-tile-product-bg:first-of-type {
  top: var(--RS-space-32-n);
  right: var(--RS-space-32-n);
  background-color: var(--RS-accent-color-primary);
  z-index: -1;
  transform: skewX(8deg)rotateZ(15deg);
}

@media (min-width: 860px) {
  .RS-tile-product-bg:first-of-type {
    top: 0;
    right: 0;
  }
}

.RS-tile-product-bg:nth-of-type(2) {
  bottom: var(--RS-space-32-n);
  left: var(--RS-space-32-n);
  background-color: var(--RS-accent-color-secondary);
  z-index: -1;
  transform: skewX(-8deg)rotateZ(-15deg);
}

@media (min-width: 860px) {
  .RS-tile-product-bg:nth-of-type(2) {
    bottom: 0;
    left: 0;
  }
}

.RS-tile-product-image--desktop {
  margin-left: calc(var(--RS-space-256-n)  + var(--RS-space-192-n));
}

.RS-tile-product-image--mobile {
  position: absolute;
  bottom: 0;
  right: 0;
}

.RS-tile-product-bg--hero {
  width: calc(var(--RS-space-256)  + var(--RS-space-64));
  height: calc(var(--RS-space-256)  + var(--RS-space-64));
  border-radius: var(--RS-space-64);
  position: absolute;
}

.RS-tile-product-bg--hero:first-of-type {
  top: var(--RS-space-32-n);
  right: var(--RS-space-32-n);
  background-color: var(--RS-accent-color-primary);
  z-index: -1;
  transform: skewX(8deg)rotateZ(15deg);
}

@media (min-width: 860px) {
  .RS-tile-product-bg--hero:first-of-type {
    top: 0;
    right: 0;
  }
}

.RS-tile-product-bg--hero:nth-of-type(2) {
  bottom: var(--RS-space-32-n);
  left: var(--RS-space-32-n);
  background-color: var(--RS-accent-color-secondary);
  z-index: -1;
  transform: skewX(-8deg)rotateZ(-15deg);
}

@media (min-width: 860px) {
  .RS-tile-product-bg--hero:nth-of-type(2) {
    bottom: 0;
    left: 0;
  }
}

.RS-tile-product-image--hero-desktop {
  margin-right: calc(var(--RS-space-256-n)  + var(--RS-space-192-n));
}

.RS-tile-product-image--hero-mobile {
  position: absolute;
  bottom: 0;
  left: 0;
}

.RS-header__description.RS-tile-process-number {
  transition-duration: var(--RS-anim-duration-xs);
}

@media (min-width: 860px) {
  .RS-list--tiles-about-benefits:first-of-type {
    margin-top: var(--RS-space-48);
  }
}

.RS-list--tiles-about-benefits:first-of-type .RS-list__tile:first-of-type {
  background-color: unset;
  border: 2px solid;
  border-top-color: var(--RS-accent-color-primary);
  border-right-color: var(--RS-accent-color-primary);
  border-bottom-color: var(--RS-accent-color-secondary);
  border-left-color: var(--RS-accent-color-secondary);
}

.RS-list--tiles-about-benefits:nth-of-type(3) .RS-list__tile:first-of-type {
  background-color: var(--RS-accent-color-primary);
  border: unset;
}

.RS-list--tiles-about-benefits:nth-of-type(3) .RS-list__tile:nth-of-type(2) {
  background-color: unset;
  border: 2px solid;
  border-top-color: var(--RS-accent-color-primary);
  border-right-color: var(--RS-accent-color-primary);
  border-bottom-color: var(--RS-accent-color-secondary);
  border-left-color: var(--RS-accent-color-secondary);
}

@media (min-width: 860px) {
  .RS-list--tiles-about-benefits:nth-of-type(3) .RS-list__tile:nth-of-type(2) {
    border-top-color: var(--RS-accent-color-secondary);
    border-right-color: var(--RS-accent-color-secondary);
    border-bottom-color: var(--RS-accent-color-primary);
    border-left-color: var(--RS-accent-color-primary);
  }
}

.RS-list--tiles-about-benefits:first-of-type .RS-list__tile:first-of-type .RS-inline-icon svg, .RS-list--tiles-about-benefits:nth-of-type(2) .RS-list__tile:first-of-type .RS-inline-icon svg {
  width: calc(var(--RS-space-32)  + var(--RS-space-4));
}

.RS-list--tiles-about-benefits:nth-of-type(3) .RS-list__tile:nth-of-type(2) svg {
  width: calc(var(--RS-space-32)  + var(--RS-space-6));
}

.RS-list--tiles-about-benefits:nth-of-type(3) .RS-list__tile:first-of-type svg {
  width: calc(var(--RS-space-32)  + var(--RS-space-4));
}

.RS-list--tiles-about-benefits:first-of-type .RS-list__tile:first-of-type svg path {
  fill: var(--RS-brand-color);
}

.RS-list--tiles-about-benefits:nth-of-type(2) .RS-list__tile:first-of-type .RS-inline-icon svg path, .RS-list--tiles-about-benefits:nth-of-type(3) .RS-list__tile:first-of-type svg path {
  fill: var(--RS-black-color);
}

.RS-list--tiles-about-benefits:nth-of-type(3) .RS-list__tile:nth-of-type(2) svg path {
  fill: var(--RS-brand-color);
}

.RS-list--tiles-about-product-alt .RS-list__tile:nth-of-type(2) svg path, .RS-list--tiles-about-product-alt .RS-list__tile:nth-of-type(4) svg path {
  fill: var(--RS-black-color);
}

.RS-list__tile--accent-primary, .RS-list__tile--accent-secondary, .RS-list__tile--brand, .RS-list__tile--graphite, .RS-list__tile--white, .RS-list__tile--no-bg, .RS-list__tile--no-bg-accents {
  padding: var(--RS-space-24);
}

@media (min-width: 1024px) {
  :is(.RS-list__tile--accent-primary, .RS-list__tile--accent-secondary, .RS-list__tile--brand, .RS-list__tile--graphite, .RS-list__tile--white, .RS-list__tile--no-bg, .RS-list__tile--no-bg-accents) {
    padding: var(--RS-space-32) var(--RS-space-32);
  }
}

.RS-list__tile--accent-primary {
  background-color: var(--RS-accent-color-primary);
}

.RS-list__tile--accent-secondary {
  background-color: var(--RS-accent-color-secondary);
}

.RS-list__tile--brand {
  background-color: var(--RS-brand-color);
  color: var(--RS-white-color);
}

.RS-list__tile--graphite {
  background-color: var(--RS-graphite-color);
  color: var(--RS-white-color);
}

.RS-list__tile--white {
  background-color: var(--RS-white-color);
}

.RS-list__tile--graphite p {
  color: var(--RS-white-color);
}

.RS-list__tile--no-bg {
  border: var(--RS-space-1) solid var(--RS-light-grey-color);
}

.RS-list__tile--no-bg-accents {
  border: var(--RS-space-2) solid var(--RS-accent-color-primary);
  border-right-color: var(--RS-accent-color-secondary);
  border-bottom-color: var(--RS-accent-color-secondary);
}

.RS-form .RS-header__description + .RS-header__description {
  margin-top: 0;
}

.RS-form .RS-header__description + .RS-button {
  margin-top: var(--RS-space-16);
}

.RS-list__tile .RS-header__description + .RS-button {
  margin-top: var(--RS-space-12);
}

.RS-list__tile img {
  border-radius: calc(var(--RS-space-24)  - var(--RS-space-4));
}

.RS-list__tile .RS-pill {
  background-color: var(--RS-graphite-color);
  padding: calc(var(--RS-space-6)  + var(--RS-space-1)) var(--RS-space-16);
  color: var(--RS-white-color);
  font-size: var(--RS-font-size-disclaimer);
  width: -moz-fit-content;
  width: fit-content;
  border-radius: var(--RS-space-16);
  gap: var(--RS-space-4);
  text-align: center;
  transition: transform var(--RS-anim-duration-lg) var(--RS-default-transition), opacity var(--RS-anim-duration-xs) var(--RS-default-transition);
  align-items: center;
  display: flex;
  transform: translate3d(0, 0, 0);
}

@media (min-width: 1024px) {
  .RS-list__tile .RS-pill {
    font-size: var(--RS-font-size-disclaimer-desktop);
    padding: var(--RS-space-8) var(--RS-space-16);
  }
}

.RS-list__tile--about-products .RS-pill {
  margin-top: calc(var(--RS-space-32-n)  - var(--RS-space-4));
  position: absolute;
}

@media (min-width: 1024px) {
  .RS-list__tile--about-products .RS-pill {
    margin-top: var(--RS-space-48-n);
  }
}

.RS-list__tile .RS-pill:before, .RS-list__tile .RS-pill:after {
  content: "";
  width: calc(var(--RS-space-12)  - var(--RS-space-1));
  height: calc(var(--RS-space-12)  - var(--RS-space-1));
  margin-right: var(--RS-space-1);
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000000' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.7878 3.10215C11.283 2.09877 12.7138 2.09876 13.209 3.10215L15.567 7.87987L20.8395 8.64601C21.9468 8.80691 22.3889 10.1677 21.5877 10.9487L17.7724 14.6676L18.6731 19.9189C18.8622 21.0217 17.7047 21.8627 16.7143 21.342L11.9984 18.8627L7.28252 21.342C6.29213 21.8627 5.13459 21.0217 5.32374 19.9189L6.2244 14.6676L2.40916 10.9487C1.60791 10.1677 2.05005 8.80691 3.15735 8.64601L8.42988 7.87987L10.7878 3.10215Z' fill='%23ffe9ca'/%3E%3C/svg%3E%0A");
  background-size: contain;
  display: inline-block;
}

@media (min-width: 1024px) {
  :is(.RS-list__tile .RS-pill:before, .RS-list__tile .RS-pill:after) {
    width: calc(var(--RS-space-12)  + var(--RS-space-1));
    height: calc(var(--RS-space-12)  + var(--RS-space-1));
    margin-right: var(--RS-space-2);
    transform: translateY(-5%);
  }
}

.RS-marquee-wrapper {
  padding: var(--RS-space-48) 0;
  width: 100%;
  cursor: pointer;
  position: relative;
}

@media (min-width: 1024px) {
  .RS-marquee-wrapper {
    padding: var(--RS-space-64) 0;
    width: 100%;
    margin-left: 0;
  }
}

.RS-marquee__item svg {
  height: var(--RS-space-32);
  margin: 0 auto;
}

.RS-marquee {
  width: var(--RS-col-8);
}

.RS-marquee--narrow {
  width: var(--RS-col-6);
}

@media (min-width: 1024px) {
  .RS-marquee--facts {
    -webkit-mask-image: linear-gradient(90deg, #0000 0%, #000 15% 85%, #0000 100%);
    mask-image: linear-gradient(90deg, #0000 0%, #000 15% 85%, #0000 100%);
  }
}

@media (min-width: 1024px) {
  .RS-marquee--personas {
    -webkit-mask-image: linear-gradient(90deg, #0000 0%, #000 15% 85%, #0000 100%);
    mask-image: linear-gradient(90deg, #0000 0%, #000 15% 85%, #0000 100%);
  }
}

.RS-hero-marquee {
  margin-bottom: var(--RS-space-8);
}

.RS-hero-marquee__inner {
  height: 340px;
  width: 100%;
  max-width: calc(var(--RS-space-256)  + var(--RS-space-128)  + var(--RS-space-64)  + var(--RS-space-32));
  flex-direction: column;
  margin: 0 auto;
  display: flex;
  position: relative;
}

@media (min-width: 860px) {
  .RS-hero-marquee__inner {
    padding: var(--RS-space-96) var(--RS-space-32);
    flex-direction: column;
  }
}

.RS-hero-marquee__item {
  width: calc(50% - var(--RS-space-8));
  width: 70%;
  height: auto;
  bottom: var(--RS-space-48);
  align-items: center;
  display: flex;
  position: absolute;
}

.RS-hero-marquee__item > div {
  padding: calc(var(--RS-space-16)  - var(--RS-space-2));
  width: 100%;
  gap: var(--RS-space-6);
  flex-direction: column;
  flex: none;
  display: inline-flex;
  position: relative;
}

@media (min-width: 860px) {
  .RS-hero-marquee__item > div {
    padding: calc(var(--RS-space-16)  + var(--RS-space-2));
  }
}

.RS-hero-marquee__item:nth-of-type(odd) {
  left: 0;
}

.RS-hero-marquee__item:nth-of-type(odd) > div {
  background-color: var(--RS-accent-color-primary);
  border-radius: var(--RS-space-12) var(--RS-space-12) var(--RS-space-12) var(--RS-space-2);
}

.RS-hero-marquee__item:nth-of-type(2n) {
  right: 0;
}

.RS-hero-marquee__item:nth-of-type(2n) > div {
  background-color: var(--RS-dark-grey-color);
  background-color: var(--RS-graphite-color);
  border-radius: var(--RS-space-12) var(--RS-space-12) var(--RS-space-2) var(--RS-space-12);
  color: var(--RS-white-color);
}

.RS-hero-marquee__item > div > p {
  z-index: 1;
}

.RS-hero-marquee__item > div > p {
  font-size: var(--RS-font-size-label);
  font-weight: var(--RS-font-weight-variable-regular);
  line-height: var(--RS-line-height-lg);
}

@media (min-width: 1024px) {
  .RS-hero-marquee__item > div > p {
    font-size: var(--RS-font-size-p-desktop);
    line-height: var(--RS-line-height-lg);
  }
}

.RS-hero-marquee__item > div > picture {
  bottom: 0;
  left: calc(0% - var(--RS-space-48));
  width: var(--RS-space-48);
  height: var(--RS-space-48);
  aspect-ratio: 1 / 1;
  z-index: 0;
  border-radius: 50%;
  display: none;
  position: absolute;
  transform: translate(-25%);
}

@media (min-width: 860px) {
  .RS-hero-marquee__item > div > picture {
    display: block;
  }
}

@media (min-width: 1024px) {
  .RS-hero-marquee__item > div > picture {
    display: none;
  }
}

@media (min-width: 1280px) {
  .RS-hero-marquee__item > div > picture {
    display: block;
  }
}

@keyframes animHeroMarqueeImg {
  0% {
    opacity: .7;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: .7;
  }
}

.RS-hero-marquee__reaction {
  right: var(--RS-space-16);
  bottom: var(--RS-space-12-n);
  width: var(--RS-space-24);
  height: var(--RS-space-24);
  background: var(--RS-white-color);
  box-shadow: inset 0 0 0 1px var(--RS-dark-light-color);
  opacity: 0;
  transition: opacity var(--RS-anim-duration-sm) var(--RS-default-transition), transform var(--RS-anim-duration-xlg) var(--RS-bounce-transition);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  transform: translateY(50%)translateX(-300%)scale(.34);
}

.RS-hero-marquee__reaction > svg {
  width: calc(var(--RS-space-24)  - var(--RS-space-6));
  height: calc(var(--RS-space-24)  - var(--RS-space-6));
}

.RS-hero-marquee__reaction--visible {
  opacity: 1;
  transform: translateY(0)translateX(0)scale(1);
}

.RS-marquee-nav {
  height: 100%;
  color: var(--RS-brand-color);
  z-index: 2;
  padding: var(--RS-space-12);
  display: none;
  position: absolute;
  top: 0;
}

@media (min-width: 1024px) {
  .RS-marquee-nav {
    align-items: center;
    display: flex;
  }
}

.RS-marquee-nav--prev {
  left: var(--RS-space-16-n);
}

.RS-marquee-nav--next {
  right: var(--RS-space-16-n);
}

.RS-marquee-nav svg {
  width: calc(var(--RS-space-12)  - var(--RS-space-2));
}

.RS-marquee__source {
  font-size: var(--RS-font-size-disclaimer);
  gap: var(--RS-space-4);
  margin-top: auto;
  display: flex;
}

.RS-marquee__source a {
  color: var(--RS-dark-grey-color);
  font-weight: var(--RS-font-weight-variable-bold);
}

.RS-marquee__source a:hover, .RS-marquee__source a:focus {
  color: var(--RS-brand-color);
}

.RS-marquee__item .RS-list__tile {
  height: 100%;
}

.RS-background {
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: -1;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.RS-background > picture > img {
  width: 200%;
  opacity: .5;
  margin-left: -50%;
}

@media (min-width: 860px) {
  .RS-background > picture > img {
    width: 150%;
    margin-left: -25%;
  }
}

@media (min-width: 1024px) {
  .RS-background > picture > img {
    width: 100%;
    margin-left: auto;
  }
}

.RS-section-about-process .RS-background {
  top: 33%;
  left: unset;
  right: 3.5%;
}

.RS-section-about-facts .RS-background {
  top: -10%;
}

.RS-section-newsletter .RS-background {
  top: unset;
  bottom: 15%;
}

.RS-background__decoration {
  position: absolute;
}

.RS-hero .RS-background .RS-background__decoration:first-of-type {
  top: 10%;
  right: 0%;
}

@media (min-width: 1024px) {
  .RS-hero .RS-background .RS-background__decoration:first-of-type {
    top: 25%;
    right: 16.5%;
  }
}

.RS-hero .RS-background .RS-background__decoration:last-of-type {
  bottom: 50%;
  left: -0%;
}

@media (min-width: 1024px) {
  .RS-hero .RS-background .RS-background__decoration:last-of-type {
    bottom: 25%;
    left: 16.5%;
  }
}

.RS-section-about-project .RS-background .RS-background__decoration:first-of-type {
  top: 5%;
  right: -1%;
}

.RS-footer .RS-background__decoration:first-of-type {
  bottom: 50%;
  left: 15%;
}

.RS-background__decoration picture {
  width: 50%;
  height: 50%;
}

@media (min-width: 860px) {
  .RS-background__decoration picture {
    width: 75%;
    height: 75%;
  }
}

@media (min-width: 1024px) {
  .RS-background__decoration picture {
    width: 100%;
    height: 100%;
  }
}

.RS-header {
  position: relative;
}

.RS-header--centered {
  text-align: center;
}

@media (min-width: 860px) {
  .RS-header--wide {
    max-width: var(--RS-col-7);
  }
}

.RS-header--spaced {
  margin: var(--RS-space-32) auto var(--RS-space-8);
}

@media (min-width: 860px) {
  .RS-header--spaced {
    margin: 0 auto var(--RS-space-32);
  }
}

.RS-header--spaced-alt {
  margin: var(--RS-space-64) auto var(--RS-space-8);
}

@media (min-width: 860px) {
  .RS-header--spaced-alt {
    margin: var(--RS-space-64) auto 0;
  }
}

@media (min-width: 860px) {
  .RS-header--social {
    max-width: var(--RS-col-6);
  }
}

.RS-header--about-user .RS-header--about-user__decor-wrapper {
  z-index: -1;
  position: absolute;
}

.RS-header--about-user .RS-header--about-user__decor {
  content: "";
  width: calc(var(--RS-space-256)  + var(--RS-space-4)  + var(--RS-space-32));
  height: calc(var(--RS-space-256)  - 0px);
  border-radius: var(--RS-space-64);
  background: var(--RS-accent-color-primary);
  display: none;
  transform: skewX(-8deg)rotateZ(-15deg);
}

@media (min-width: 860px) {
  .RS-header--about-user .RS-header--about-user__decor {
    display: block;
  }
}

.RS-header__headline {
  margin: 0 0 var(--RS-space-12);
  font-size: var(--RS-font-size-h3);
  text-transform: uppercase;
  letter-spacing: .15em;
  display: inline-flex;
}

@media (min-width: 1024px) {
  .RS-header__headline {
    font-size: var(--RS-font-size-h3);
  }
}

.RS-header__description {
  font-size: var(--RS-font-size-h1);
  line-height: var(--RS-line-height-md);
  font-weight: var(--RS-font-weight-variable-regular);
  font-family: PlayfairDisplay-Variable, system-ui, serif;
}

@media (min-width: 1280px) {
  .RS-header__description {
    font-size: var(--RS-font-size-h1-desktop);
    line-height: var(--RS-line-height-md);
  }
}

.RS-header__description--hero {
  font-size: var(--RS-font-size-h1--hero);
  text-align: center;
}

@media (min-width: 1024px) {
  .RS-header__description--hero {
    text-align: left;
  }
}

@media (min-width: 1280px) {
  .RS-header__description--hero {
    font-size: var(--RS-font-size-h1--hero-desktop);
  }
}

.RS-header__description--pointed {
  gap: var(--RS-space-6);
  display: flex;
}

.RS-header--about-user .RS-header__description {
  max-width: calc(100% - var(--RS-space-16));
}

.RS-header__description + .RS-header__description {
  margin: var(--RS-space-32) 0 0 0;
}

.RS-header__description--hero + .RS-header__description--primary {
  margin: var(--RS-space-8) 0 0 0;
}

.RS-header__description + .RS-header__description--secondary {
  margin: calc(var(--RS-space-48)  - var(--RS-space-16)) 0 0 0;
}

@media (min-width: 860px) {
  .RS-header__description + .RS-header__description--secondary {
    margin: calc(var(--RS-space-48)  - var(--RS-space-2)) 0 0 0;
  }
}

.RS-section-about-product .RS-header__description + .RS-header__description--primary, .RS-section-about-product .RS-header__description + .RS-header__description--secondary {
  margin: 0;
}

@media (min-width: 860px) {
  :is(.RS-section-about-product .RS-header__description + .RS-header__description--primary, .RS-section-about-product .RS-header__description + .RS-header__description--secondary) {
    margin: 0;
  }
}

.RS-header__description:not(.RS-header__description--secondary) + .RS-header__description--secondary {
  margin: calc(var(--RS-space-48)  - var(--RS-space-16)) 0 0 0;
}

.RS-header__description.RS-header__description--tile + .RS-header__description--secondary {
  margin: var(--RS-space-24) 0 0 0;
}

.RS-header__description + .RS-button {
  margin: var(--RS-space-48) 0 0 0;
}

.RS-header__description--secondary {
  font-size: var(--RS-font-size-h2);
  line-height: var(--RS-line-height-xlg);
  font-weight: var(--RS-font-weight-variable-light);
  font-family: PublicSans-Variable, system-ui, sans-serif;
}

@media (min-width: 1024px) {
  .RS-header__description--secondary {
    font-size: var(--RS-font-size-h2-desktop);
  }
}

.RS-header__description--tile {
  font-size: var(--RS-font-size-h3);
  align-items: center;
  gap: var(--RS-space-8);
  display: flex;
  position: relative;
}

@media (min-width: 1024px) {
  .RS-header__description--tile {
    font-size: var(--RS-font-size-h3-desktop);
  }
}

.RS-header__headline + .RS-header__description--secondary {
  margin: var(--RS-space-24) 0 0 0;
}

.RS-header__description-link {
  color: inherit;
  transition: opacity var(--RS-anim-duration-sm) var(--RS-default-transition);
  display: inline-flex;
  position: relative;
}

.RS-header__description-link:hover {
  opacity: 1;
  box-shadow: inset 0 0 #0000;
}

.RS-header__description-link:before {
  content: "";
  width: 100%;
  height: var(--RS-space-2);
  background-color: var(--RS-black-color);
  transform-origin: 0;
  transition: transform var(--RS-anim-duration-xlg) var(--RS-default-transition);
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.RS-header__description-link:hover:before {
  transform-origin: 100%;
  background-color: var(--RS-brand-color);
  transform: scaleX(0);
}

.RS-header .RS-header__button {
  margin: var(--RS-space-32) 0 0;
}

@media (min-width: 860px) {
  .RS-header .RS-header__button {
    margin: var(--RS-space-48) 0 0;
  }
}

.RS-header__description {
  transition-duration: calc(var(--RS-anim-duration-xlg)  + var(--RS-anim-duration-lg));
}

.RS-header .RS-header__link, .RS-header__artwork {
  transition-duration: 1.5s;
}

.RS-header-secondary-icon {
  transform: translateY(var(--RS-space-6));
  display: inline-flex;
}

.RS-header-secondary-icon > svg {
  height: calc(var(--RS-space-24)  + var(--RS-space-2));
  width: calc(var(--RS-space-24)  + var(--RS-space-2));
}

@media (min-width: 860px) {
  .RS-header-secondary-icon > svg {
    height: calc(var(--RS-space-32)  + var(--RS-space-2));
    width: calc(var(--RS-space-32)  + var(--RS-space-2));
  }
}

.RS-header__text-italic {
  font-family: PlayfairDisplay-Italic-Variable, system-ui, serif;
}

.RS-header__text-italic--flex {
  margin-left: var(--RS-space-1-n);
}

@media (min-width: 860px) {
  .RS-header__text-italic--flex {
    margin-left: var(--RS-space-2-n);
  }
}

.RS-header__description--highlighted {
  margin-bottom: var(--RS-space-32);
  background-color: var(--RS-graphite-color);
  padding: var(--RS-space-16) var(--RS-space-24);
  border-radius: var(--RS-space-16);
  color: var(--RS-white-color);
}

@media (min-width: 860px) {
  .RS-header__description--highlighted {
    margin-bottom: var(--RS-space-64);
  }
}

.RS-header__slider {
  margin: 0 auto;
}

.RS-section-about-process .RS-header__description--tile:before {
  width: calc(var(--RS-space-32)  + var(--RS-space-8));
  height: calc(var(--RS-space-32)  + var(--RS-space-8));
  z-index: -1;
  opacity: .2;
  border: 3px solid #00000080;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-33%)translateY(-20%)rotateZ(45deg);
  -webkit-mask-image: linear-gradient(90deg, #000 0% 49.9%, #0000 50.1% 100%);
  mask-image: linear-gradient(90deg, #000 0% 49.9%, #0000 50.1% 100%);
}

.RS-list--tiles-about-product .RS-header__description + .RS-button {
  margin: var(--RS-space-24) 0 0 0;
}

.RS-list--tiles-about-product:not(.RS-list--tiles-about-product--alt) .RS-header__description:last-of-type {
  margin-top: 0;
}

.RS-list__tile .RS-header__description--secondary + .RS-header__description--secondary {
  margin: var(--RS-space-8) 0 0 0;
}

@media (min-width: 860px) {
  .RS-list__tile .RS-header__description--secondary + .RS-header__description--secondary {
    margin: var(--RS-space-16) 0 0 0;
  }
}

.RS-list__tile .RS-header__description--pointed + .RS-header__description--pointed {
  margin: var(--RS-space-4) 0 0 0;
}

@media (min-width: 860px) {
  .RS-list__tile .RS-header__description--pointed + .RS-header__description--pointed {
    margin: var(--RS-space-4) 0 0 0;
  }
}

.RS-article {
  width: 100%;
  max-width: var(--RS-col-6);
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  display: flex;
}

.RS-article--centered {
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.RS-article--block {
  display: block;
}

.RS-article--cols {
  gap: var(--RS-space-24);
  align-items: center;
}

@media (min-width: 1024px) {
  .RS-article--cols {
    gap: var(--RS-space-96);
    flex-direction: row;
  }
}

@media (min-width: 1024px) {
  .RS-article--reverse {
    flex-direction: row-reverse;
  }
}

.RS-article--expert {
  padding: 0 var(--RS-space-24) var(--RS-space-32) var(--RS-space-24);
  border-radius: var(--RS-space-32);
  background-color: var(--RS-accent-color-secondary);
  flex-direction: column-reverse;
  gap: 0;
}

@media (min-width: 1024px) {
  .RS-article--expert {
    padding: 0 var(--RS-space-48) var(--RS-space-24) var(--RS-space-48);
    gap: var(--RS-space-96);
    flex-direction: row;
  }
}

.RS-article--expert.RS-article--reverse {
  background-color: var(--RS-accent-color-primary);
}

@media (min-width: 1024px) {
  .RS-article--expert.RS-article--reverse {
    flex-direction: row-reverse;
  }
}

.RS-article--wide {
  max-width: 100%;
}

@media (min-width: 1024px) {
  .RS-article--wide {
    max-width: var(--RS-col-8);
  }
}

.RS-article--hero {
  gap: var(--RS-space-24);
  align-items: center;
}

@media (min-width: 1024px) {
  .RS-article--hero {
    gap: var(--RS-space-96);
    gap: var(--RS-space-64);
    flex-direction: row;
  }
}

.RS-article--hero > header {
  width: 100%;
  text-align: center;
  margin: 0 auto;
}

@media (min-width: 1024px) {
  .RS-article--hero > header {
    width: 50%;
    width: calc(50% + var(--RS-space-64));
    text-align: left;
    margin: 0;
  }
}

.RS-article--hero > .RS-content {
  width: 100%;
  margin: var(--RS-space-64) 0;
}

@media (min-width: 1024px) {
  .RS-article--hero > .RS-content {
    width: 50%;
    width: calc(50% - var(--RS-space-64));
  }
}

.RS-article > svg {
  height: auto;
  margin: 0 0 var(--RS-space-48);
}

@media (min-width: 860px) {
  .RS-article > svg {
    margin: 0 0 var(--RS-space-64);
  }
}

@media (min-width: 1024px) {
  .RS-article > svg {
    margin: 0;
  }
}

.RS-article + .RS-article {
  margin-top: var(--RS-space-48);
}

@media (min-width: 860px) {
  .RS-article + .RS-article {
    margin-top: var(--RS-space-64);
  }
}

.RS-article--expert + .RS-article--expert {
  margin-top: var(--RS-space-64);
}

@media (min-width: 860px) {
  .RS-article--expert + .RS-article--expert {
    margin-top: var(--RS-space-64);
  }
}

.RS-hero {
  z-index: 1;
  padding: 15.5vh var(--RS-space-24) 2.5vh;
  align-items: center;
  display: flex;
  position: relative;
}

@media (max-height: 414px) and (max-width: 1024px) {
  .RS-hero {
    height: auto;
  }
}

@media (min-width: 860px) {
  .RS-hero {
    padding: 12vh var(--RS-space-24) 4vh;
  }
}

@media (min-width: 1024px) {
  .RS-hero {
    min-height: 80vh;
    padding: 12vh var(--RS-space-32) var(--RS-space-16);
    margin: 0;
  }
}

@media (min-width: 1024px) {
  .RS-hero--confirmation-early-access {
    min-height: 85vh;
  }
}

.RS-hero__container {
  z-index: 1;
  width: 100%;
  max-width: var(--RS-col-8);
  min-height: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  display: flex;
  position: relative;
}

.RS-footer {
  padding: var(--RS-space-64) var(--RS-space-24) var(--RS-space-32);
  position: relative;
  overflow: hidden;
}

@media (min-width: 860px) {
  .RS-footer {
    padding: 0 var(--RS-space-32) var(--RS-space-32) var(--RS-space-32);
  }
}

.RS-footer__container {
  max-width: var(--RS-col-8);
  margin: 0 auto;
}

.RS-footer__copyright {
  font-size: var(--RS-font-size-disclaimer);
  text-transform: uppercase;
  letter-spacing: .1em;
  font-weight: var(--RS-font-weight-variable-bold);
}

@media (min-width: 1024px) {
  .RS-footer__copyright {
    font-size: var(--RS-font-size-disclaimer-desktop);
  }
}

.RS-footer__navigation {
  margin: var(--RS-space-8) 0;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

@media (min-width: 860px) {
  .RS-footer__navigation {
    margin: var(--RS-space-48) 0;
    flex-direction: row;
  }
}

.RS-footer .RS-link {
  font-weight: var(--RS-font-weight-variable-bold);
}

.RS-footer .RS-divider {
  margin: 0 var(--RS-space-16);
  margin-top: var(--RS-space-8);
  width: var(--RS-space-6);
  height: var(--RS-space-6);
  border-radius: 50%;
  display: flex;
}

@media (min-width: 860px) {
  .RS-footer .RS-divider {
    margin-bottom: var(--RS-space-2);
    background: var(--RS-brand-color);
    display: inline-flex;
  }
}

.RS-footer__col {
  font-size: var(--RS-font-size-label);
  line-height: var(--RS-line-height-xlg);
  margin-bottom: var(--RS-space-48);
}

@media (min-width: 860px) {
  .RS-footer__col {
    margin-bottom: 0;
    display: block;
  }
}

@media (min-width: 1280px) {
  .RS-footer__col {
    font-size: var(--RS-font-size-label-desktop);
  }
}

.RS-footer__container > .RS-footer__col {
  margin: 0;
}

.RS-footer__col .RS-link svg {
  margin-left: var(--RS-space-4);
}

.RS-footer__disclaimer {
  font-size: var(--RS-font-size-disclaimer);
  font-weight: var(--RS-font-weight-variable-regular);
  transform: translateY(calc(-100% - var(--RS-space-2)));
  position: absolute;
}

@media (min-width: 1024px) {
  .RS-footer__disclaimer {
    font-size: var(--RS-font-size-disclaimer-desktop);
    transform: translateY(calc(-100% - var(--RS-space-4)));
  }
}

.RS-footer__decor {
  content: "";
  width: calc(var(--RS-space-256)  + var(--RS-space-4)  + var(--RS-space-32));
  height: calc(var(--RS-space-256)  - 0px);
  border-radius: var(--RS-space-64);
  background: var(--RS-accent-color-primary);
  z-index: -1;
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateX(-50%)skewX(8deg)rotateZ(15deg);
}

@media (min-width: 860px) {
  .RS-footer__decor {
    display: block;
  }
}

.RS-cookie-bar {
  left: var(--RS-space-8);
  bottom: calc(var(--RS-space-24)  - var(--RS-space-4));
  z-index: 666;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  opacity: 0;
  transition: transform var(--RS-anim-duration-xlg) var(--RS-default-transition), opacity var(--RS-anim-duration-md) var(--RS-default-transition);
  transform: translate3d(0, var(--RS-space-32), 0);
  backface-visibility: hidden;
  transform-style: preserve-3d;
  width: calc(100% - var(--RS-space-8) * 2);
  position: fixed;
}

@media (min-width: 480px) {
  .RS-cookie-bar {
    width: auto;
    left: var(--RS-space-32);
    bottom: var(--RS-space-32);
  }
}

.RS-cookie-bar.RS-cookie-bar--visible {
  pointer-events: all;
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.RS-cookie-bar__box {
  padding: var(--RS-space-16) var(--RS-space-24);
  background-color: var(--RS-accent-color-primary);
  border-radius: 16px;
}

.RS-cookie-bar__content {
  align-items: center;
  gap: var(--RS-space-12);
  flex-direction: column;
  display: flex;
}

@media (min-width: 480px) {
  .RS-cookie-bar__content {
    gap: var(--RS-space-24);
    flex-direction: row;
  }
}

.RS-cookie-bar__title {
  margin: 0 0 var(--RS-space-8);
  font-size: 32px;
  line-height: 40px;
}

@media (min-width: 860px) {
  .RS-cookie-bar__title {
    font-size: 40px;
    line-height: 48px;
  }
}

.RS-cookie-bar__title-icon {
  width: 32px;
  height: 32px;
  vertical-align: middle;
  fill: currentColor;
  display: inline-block;
  position: relative;
  top: -1px;
}

@media (min-width: 860px) {
  .RS-cookie-bar__title-icon {
    width: 40px;
    height: 48px;
    top: -2px;
  }
}

.RS-cookie-bar__actions {
  gap: var(--RS-space-16);
  align-items: center;
  display: flex;
}

/*# sourceMappingURL=index.98659ebc.css.map */
