/* Fonts */

@font-face {
  font-family: 'PublicSans-Variable';
  src: url('../../static/PublicSans-Variable.woff2') format('woff2'),
       url('../../static/PublicSans-Variable.woff') format('woff'),
       url('../../static/PublicSans-Variable.ttf') format('truetype');
  font-weight: 100 1000;
  font-stretch: 75% 125%;
  font-display: swap;
}

/* @font-face {
     font-family: 'Labrada-Variable';
     src: url('../../static/Labrada-Variable.woff2') format('woff2'),
          url('../../static/Labrada-Variable.woff') format('woff'),
          url('../../static/Labrada-Variable.ttf') format('truetype');
     font-weight: 100 1000;
     font-stretch: 75% 125%;
     font-display: swap;
   }


@font-face {
font-family: 'Labrada-Italic-Variable';
src: url('../../static/Labrada-Italic-Variable.woff2') format('woff2'),
     url('../../static/Labrada-Italic-Variable.woff') format('woff'),
     url('../../static/Labrada-Italic-Variable.ttf') format('truetype');
font-weight: 100 1000;
font-stretch: 75% 125%;
font-display: swap;
} */

@font-face {
     font-family: 'PlayfairDisplay-Variable';
     src: url('../../static/PlayfairDisplay-Variable.woff2') format('woff2'),
          url('../../static/PlayfairDisplay-Variable.woff') format('woff'),
          url('../../static/PlayfairDisplay-Variable.ttf') format('truetype');
     font-weight: 100 1000;
     font-stretch: 75% 125%;
     font-display: swap;
   }


@font-face {
font-family: 'PlayfairDisplay-Italic-Variable';
src: url('../../static/PlayfairDisplay-Italic-Variable.woff2') format('woff2'),
     url('../../static/PlayfairDisplay-Italic-Variable.woff') format('woff'),
     url('../../static/PlayfairDisplay-Italic-Variable.ttf') format('truetype');
font-weight: 100 1000;
font-stretch: 75% 125%;
font-display: swap;
}